import React from 'react';

const SubmitButton = ({ isFormValid, onClick, children }) => {
    return (
        <div className="button-container">
            <button disabled={!isFormValid} className="button" onClick={onClick}>
                {children}
            </button>
        </div>
    );
};

export default SubmitButton;