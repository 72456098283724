import React, {useEffect, useState} from "react";
import 'leaflet/dist/leaflet.css';
import './BalloonMapStyles.css';
import Header from "../../components/Header";
import L from 'leaflet';
import 'leaflet-tilelayer-swiss/dist/Leaflet.TileLayer.Swiss.umd.js';
import startIcon from './assets/start-icon.png';
import '@elfalem/leaflet-curve';
import {fetchWithHeaders} from "../../components/utils/api";
import {useParams} from "react-router-dom";
import {balloonColors} from '../../assets/colors/Colors';
import {colorPattern} from '../../assets/full_balloon_pattern/patternColor';

const customIcon = L.icon({
    iconUrl: startIcon,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
    popupAnchor: [25, 25]
});

const BalloonMapPublic = () => {
    const [mapInitialized, setMapInitialized] = useState(false);
    const [balloons, setBalloons] = useState([]);
    const [markers, setMarkers] = useState([]);
    const {eventId} = useParams();
    const [topBalloons, setTopBalloons] = useState([]);
    const [eventLocation, setEventLocation] = useState(null);

    // Fetch event location
    const fetchEventLocation = () => {
        fetchWithHeaders(`/api/event_table/${eventId}/location`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setEventLocation([data.y, data.x]);
            });
    };

    const displayInf = () => {
        fetchWithHeaders(`/api/event_table/${eventId}`,  {method: "GET"})
            .then(response => response.json())
            .then(data => {
                console.log("The event has started:", data.started)
                console.log("The event duration is:", data.eventTime)
            });
    }

    // Fetch balloon data
    const fetchData = () => {
        fetchWithHeaders(`/api/event_table/${eventId}/balloons`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                const newBalloons = data.map(balloon => {
                    const color = balloonColors[balloon.BalloonColor];
                    const pattern = colorPattern[balloon.Pattern][balloon.PatternColor];
                    const icon = L.divIcon({
                        className: 'custom-icon',
                        html: `<div style="position: relative; width: 70px; height: 70px; z-index: 999;">
                               <img src="${color}" style="position: absolute; top: 0; left: -28px; width: 100%; height: 100%;" />
                               <img src="${pattern}" style="position: absolute; top: 7px; left: -28px; width: 100%; height: 50%;" />
                                </div>`
                    });
                    return {...balloon, icon};
                });
                setBalloons(newBalloons);
            });
    };

    // Fetch top 10 balloons
    const fetchTop10 = () => {
        fetchWithHeaders(`/api/event_table/${eventId}/ranking10`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                const newTopBalloons = data.map(balloon => {
                    return {
                        ...balloon,
                        rank: balloon.rank === 2147483647 ? "unranked" : balloon.rank
                    };
                });
                setTopBalloons(newTopBalloons);
            });
    };

    useEffect(() => {
        fetchEventLocation();
        displayInf();
    }, [eventId]);

    useEffect(() => {
        if (eventLocation) {
            const southWest = L.latLng(-89.98155760646617, -180),
                northEast = L.latLng(89.99346179538875, 180),
                bounds = L.latLngBounds(southWest, northEast);
            window.mapCH = L.map('publicmapid', {
                center: eventLocation,
                zoom: 13,
                maxBounds: bounds,
                worldCopyJump: true
            });
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
            }).addTo(window.mapCH);

            L.marker(eventLocation, {icon: customIcon}).addTo(window.mapCH);

            setMapInitialized(true);
        }
        return () => {
            if (window.mapCH) {
                window.mapCH.remove();
                delete window.mapCH;
            }
        };
    }, [eventLocation]);

    useEffect(() => {
        if (mapInitialized) {
            fetchData();
            fetchTop10();

            const intervalId = setInterval(() => {
                fetchData();
                fetchTop10();
            }, 2000);

            return () => clearInterval(intervalId);
        }
    }, [mapInitialized]);

    useEffect(() => {
        if (balloons.length > 0 && window.mapCH) {
            markers.forEach(marker => {
                window.mapCH.removeLayer(marker);
            });

            const newMarkers = balloons.map(balloon => {
                const marker = L.marker([balloon.longitude, balloon.latitude], {icon: balloon.icon}).addTo(window.mapCH);
                marker.bindPopup(`<b>${balloon.name}</b>`);
                return marker;
            });

            const group = L.featureGroup(newMarkers);
            window.mapCH.fitBounds(group.getBounds());

            document.getElementById('balloon-counter').innerText = `${balloons.length} Balloons`;

            setMarkers(newMarkers);
        }
    }, [balloons]);

    return (
        <div className="containerPublic">
            <Header/>
            <div id="balloon-counter" style={{position: 'absolute', top: '4%', right: '48%', zIndex: 1, fontSize: '3vh'}}></div>
            <div id="publicmapid" style={{position: 'absolute', left: '0vw', top: '10%', maxHeight: '85vh'}}></div>
            <div style={{position: "absolute", top: '13vh', right: '11vw', height: '80vh', zIndex: 10}}>
                <ul>
                    {topBalloons.map((balloon, index) => (
                        <li style={{marginBottom: '3vh'}} key={index}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                                width: '5vw',
                                height: '5vw'
                            }}>
                                <div style={{marginRight: '10px'}}>
                                    <img src={balloonColors[balloon.BalloonColor]} alt="Balloon Color" style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '-28px',
                                        width: '100%',
                                        height: '100%'
                                    }}/>
                                    <img src={colorPattern[balloon.Pattern][balloon.PatternColor]} alt="Balloon Pattern"
                                         style={{
                                             position: 'absolute',
                                             top: '1vh',
                                             left: '-28px',
                                             width: '100%',
                                             height: '50%'
                                         }}/>
                                </div>
                                <div style={{marginLeft: '3vw'}}>
                                    <p>{balloon.rank}</p>
                                    <p>{balloon.name}</p>
                                </div>
                            </div>
                            <div style={{height: '40px'}}>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default BalloonMapPublic;
