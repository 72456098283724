import Cookies from "js-cookie";

export function fetchWithHeaders(endpoint, options = {}) {
    const baseURL = process.env.REACT_APP_API_URL || "";
    const url = baseURL + endpoint;

    options.headers = {
        ...options.headers,
        "X-API-KEY": "PocketBalloon"
    };

    return fetch(url, options);
}


export const getGeoLocation = (eventId) => {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            reject('Geolocation not supported');
        }
    });

    function success(position) {
        Cookies.set('latitude', position.coords.latitude, { expires: 7 });
        Cookies.set('longitude', position.coords.longitude, { expires: 7 });
        console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
    }

    function error() {
        console.log('Unable to retrieve your location');
        console.log('using event location');
        fetchWithHeaders(`/api/event_table/${eventId}/location`)
            .then(response => response.json())
            .then(data => {
                Cookies.set('latitude', data.y, { expires: 7 });
                Cookies.set('longitude', data.x, { expires: 7 });
                console.log(`Latitude: ${data.y}, Longitude: ${data.x}`);
            });
    }
};

