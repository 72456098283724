import { starPatterns } from "./stars/StarPatterns";
import { circlePatterns } from "./circles/CirclePatterns";
import { rectanglePatterns } from "./rectangles/RectanglePatterns";
import { trianglePatterns } from "./triangles/TrianglePatterns";

export const colorPattern = {
    starPatterns: starPatterns,
    circlePatterns: circlePatterns,
    rectanglePatterns: rectanglePatterns,
    trianglePatterns: trianglePatterns
}