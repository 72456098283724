// BalloonList.js
import React from 'react';
import Paper from '@mui/material/Paper';

const BalloonList = ({ balloons }) => {
    return (
        <Paper elevation={3} style={{ width: '300px', padding: '20px', marginLeft: '20px' }}>
            <h1 style={{ color: 'blue', textAlign: 'center' }}>Balloon List</h1>
            {balloons.map((balloon) => (
                <Paper
                    elevation={6}
                    style={{ margin: '10px', padding: '15px', textAlign: 'left' }}
                    key={balloon.id}
                >
                    ID: {balloon.id} <br />
                    Name: {balloon.name} <br />
                    Owner: {balloon.owner} <br />
                    Event: {balloon.event} <br />
                    Volume: {balloon.volume} <br />
                    Height: {balloon.height} <br />
                    TimeStamp: {balloon.timeStamp}<br/>

                    {/* Extracting color information */}
                    Color: {balloon.color ? `RGB(${balloon.color.red}, ${balloon.color.green}, ${balloon.color.blue})` : 'N/A'} <br />
                    Longitude: {balloon.position ? balloon.position.x : 'N/A'} <br />
                    Latitude: {balloon.position ? balloon.position.y : 'N/A'} <br />

                    {/* Displaying locations if available */}
                    Locations:
                    {balloon.locations &&
                        balloon.locations.map((location, index) => (
                            <div key={index}>
                                Location {index + 1}: X={location.x}, Y={location.y}
                            </div>
                        ))}
                </Paper>
            ))}
        </Paper>
    );
};

export default BalloonList;