import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import { balloonColors } from '../../assets/colors/Colors';

import trianglePatterns from '../../assets/patterns/PatternTriangles.svg';
import circlePatterns from '../../assets/patterns/PatternCircle.svg';
import starPatterns from '../../assets/patterns/PatternStars.svg';
import rectanglePatterns from '../../assets/patterns/PatternRectangles.svg';

import Symbol1 from '../../assets/patterns/SymbolTriangle.svg';
import Symbol2 from '../../assets/patterns/SymbolCircle.svg';
import Symbol3 from '../../assets/patterns/SymbolStar.svg';
import Symbol4 from '../../assets/patterns/SymbolRectangle.svg';

import { bgColors } from '../../components/utils/utils';
import { balloon } from '../../components/utils/utils';

import Cookies from 'js-cookie';


const patterns = {
    trianglePatterns,
    circlePatterns,
    starPatterns,
    rectanglePatterns,
};

const PatternCustomizer = () => {
    let navigate = useNavigate();
    const { eventId } = useParams();

    const [selectedPattern, setSelectedPattern] = useState('');
    const [selectedPatternName, setSelectedPatternName] = useState('');

    useEffect(() => {
        const savedPatternName = Cookies.get('selectedPattern');
        if (savedPatternName) {
            setSelectedPattern(patterns[savedPatternName]);
            setSelectedPatternName(savedPatternName);
            balloon.pattern = patterns[savedPatternName];
            balloon.patternName = savedPatternName;
        }
        const savedColor = Cookies.get('selectedColor');
        if (savedColor) {
            balloon.color = savedColor;
        }
    }, []);

    const navigateToPatternColorPage = () => {
        navigate(`/${eventId}/patternColor`);
    };

    const handlePatternSelect = (pattern, patternName) => {
        setSelectedPattern(pattern);
        setSelectedPatternName(patternName);
        balloon.pattern = pattern;
        balloon.patternName = patternName;
        Cookies.set('selectedPattern', patternName, { expires: 7 }); // Speichert den Namen des Musters
    };

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <Header />
            <div style={{ textAlign: 'center' }}>
                <img
                    src={balloonColors[balloon.color] || balloonColors.standard}
                    alt="Selected Balloon"
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                        position: 'absolute',
                        left: '50%',
                        top: '400%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
                {selectedPattern && (
                    <img
                        src={selectedPattern}
                        alt="Selected Pattern"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            position: 'absolute',
                            left: '49%',
                            top: '320%',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
                <div style={{ position: 'relative', height: '100%' }}>
                    <div
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '48vh',
                            transform: 'translateX(-50%)',
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '20px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {[
                            { pattern: trianglePatterns, symbol: Symbol1, name: "trianglePatterns" },
                            { pattern: circlePatterns, symbol: Symbol2, name: "circlePatterns" },
                            { pattern: starPatterns, symbol: Symbol3, name: "starPatterns" },
                            { pattern: rectanglePatterns, symbol: Symbol4, name: "rectanglePatterns" },
                        ].map(({ pattern, symbol, name }) => (
                            <button
                                key={name}
                                onClick={() => handlePatternSelect(pattern, name)}
                                style={{
                                    ...buttonStyle,
                                    backgroundColor: selectedPatternName === name ? '#D3D3D3' : 'transparent',
                                }}
                            >
                                <img src={symbol} alt={`Muster ${name.split('Patterns')[0]}`} style={patternImageStyle} />
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div style={{position: "absolute", left: "30%"}}>
                <button
                    type="button"
                    onClick={navigateToPatternColorPage}
                    style={{
                        position: 'absolute',
                        top: '80vh',
                        left: "20%",
                        width: "40vw",
                        height: "8vh",
                        backgroundColor: "white",
                        border: `3px solid ${bgColors.LightBlue}`,
                        borderRadius: "10px",
                        color: "darkBlue",
                        fontSize: "25px",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                    }}
                    disabled={!selectedPattern}
                >
                    Weiter
                </button>
            </div>

        </div>
    );
};

const buttonStyle = {
    backgroundColor: 'white',
    border: '3px solid #62B4EA',
    borderRadius: '10px',
    padding: '10px',
    width: '40vw',
    height: '10vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '4px 4px 5px rgba(0, 0, 0, 0.3)',
};

const patternImageStyle = {
    maxWidth: '90%',
    maxHeight: '90%',
};

export default PatternCustomizer;