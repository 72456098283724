import StarPatternStandard from '../../patterns/PatternStars.svg'
import StarPattern1 from './Star1.svg'
import StarPattern2 from './Star2.svg'
import StarPattern3 from './Star3.svg'
import StarPattern4 from './Star4.svg'
import StarPattern5 from './Star5.svg'
import StarPattern6 from './Star6.svg'
import StarPattern7 from './Star7.svg'
import StarPattern8 from './Star8.svg'
import StarPattern9 from './Star9.svg'

export const starPatterns = {
    standard: StarPatternStandard,
    B1: StarPattern1,
    B2: StarPattern2,
    B3: StarPattern3,
    B4: StarPattern4,
    B5: StarPattern5,
    B6: StarPattern6,
    B7: StarPattern7,
    B8: StarPattern8,
    B9: StarPattern9
};
