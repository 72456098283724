import React, {useEffect, useState} from 'react';
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
import logo from "./assets/aargautourismus_farb_big.jpg";
import Cookies from "js-cookie";

const OrganizerLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useAuth();    

    const handleSubmit = async (e) => {
        e.preventDefault();
        login(username, password);
    };

    return (
        <div className="font-[sans-serif] text-[#333]">
            <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                <div className="grid md:grid-cols-2 items-center gap-4 max-w-7xl w-full">
                    <div className="border-2 border-PB-400 rounded-md p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div className="mb-10">
                                <h3 className="text-3xl font-extrabold"> PocketBalloon Dashboard </h3>
                            </div>
                            <div>
                                <label className="text-sm mb-2 block">Username</label>
                                <div className="relative flex items-center">
                                    <input name="username" type="text" required onChange={(e) => setUsername(e.target.value)}
                                           className="w-full text-sm border-2 border-PB-400 px-4 py-3 rounded-md outline-[#333]"
                                           placeholder="Enter user name" />
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                         className="w-[18px] h-[18px] absolute right-4" viewBox="0 0 24 24">
                                        <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                                        <path
                                            d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                            data-original="#000000"></path>
                                    </svg>
                                </div>
                            </div>
                            <div>
                                <label className="text-sm mb-2 block">Password</label>
                                <div className="relative flex items-center">
                                    <input name="password" type="password" required onChange={(e) => setPassword(e.target.value)}
                                           className="w-full text-sm border-2 border-PB-400 px-4 py-3 rounded-md outline-[#333]"
                                           placeholder="Enter password" />
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                         className="w-[18px] h-[18px] absolute right-4 cursor-pointer"
                                         viewBox="0 0 128 128">
                                        <path
                                            d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                            data-original="#000000"></path>
                                    </svg>
                                </div>
                            </div>
                            <div className="!mt-10">
                                <button
                                    type="submit"
                                    className="w-full shadow-xl py-2.5 px-4 text-sm font-semibold rounded text-black border-2 border-PB-400 bg-blue-200 hover:bg-blue-100 focus:outline-none">
                                    Login
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="lg:h-[250px] md:h-[300px] max-md:mt-10">
                        <img src={logo} alt="Balloon" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganizerLogin;