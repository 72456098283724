import Header from "./Header";
import Explorer from "./Explorer";
import './organizerDashboard.css';




const OrganizerExplorer = () => {

    return (
        <div>
            <Header/>
            <Explorer/>
        </div>
    )
};

export default OrganizerExplorer;
