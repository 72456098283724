import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import { fetchWithHeaders ,  baseURL } from "../utils/api";
const UserList = () => {
    const [users, setUsers] = useState([]);

    const fetchUserData = () => {
        fetchWithHeaders(`/api/User_table/getAll`)
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => b.id - a.id);
                setUsers(data);
            });
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    return (
        <Paper elevation={3} style={{ width: "300px", padding: "20px" ,margin:'10px'}}>
            <h1 style={{ color: "blue", textAlign: "center" }}>User List</h1>
            {users.map((user) => (
                <Paper
                    elevation={6}
                    style={{ margin: "10px", padding: "15px", textAlign: "left" }}
                    key={user.email}
                >
                    <div>
                        <strong>Name:</strong> {user.name}
                    </div>
                    <div>
                        <strong>E-Mail:</strong> {user.email}
                    </div>
                    <div>
                        <strong>Events:</strong> {user.events.join(", ")}
                    </div>
                    <div>
                        <strong>Address:</strong>{" "}
                        {`${user.address.split(",")[0]}, ${user.address.split(",")[1]}, ${user.address.split(",")[2]}`}
                    </div>
                </Paper>
            ))}
        </Paper>
    );
};

export default UserList;
