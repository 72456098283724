import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'leaflet/dist/leaflet.css';
import './BalloonMapStyles.css';
import Header from "../../components/Header";
import L from 'leaflet';
import 'leaflet-tilelayer-swiss/dist/Leaflet.TileLayer.Swiss.umd.js';
import { fetchWithHeaders } from "../../components/utils/api";
import { useParams } from "react-router-dom";
import { balloonColors } from '../../assets/colors/Colors';
import Cookies from 'js-cookie';
import { colorPattern } from '../../assets/full_balloon_pattern/patternColor';
import startIcon from "./assets/start-icon.png";
import SubmitButton from "../../components/utils/SubmitButton";

let map = 'mapCH_mapid';

const customIcon = L.icon({
    iconUrl: startIcon,
    iconSize: [50, 50],
    iconAnchor: [25, 25],
    popupAnchor: [25, 25]
});

export const initializeMap = (zoomLevel, mapid) => {
    if (!window['mapCH_' + mapid]) {
        const southWest = L.latLng(-89.98155760646617, -180),
            northEast = L.latLng(89.99346179538875, 180),
            bounds = L.latLngBounds(southWest, northEast);


        const centerPoint = [47.48147644252481, 8.211638130753899]; // Adjust these values as needed
        window['mapCH_' + mapid] = L.map(mapid, {
            worldCopyJump: true,
            maxBounds: bounds,
            center: centerPoint,
            zoom: zoomLevel, // Consider setting a higher initial zoom
            maxZoom: 19
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(window['mapCH_' + mapid]);
    }

    return () => {
        if (window['mapCH_' + mapid]) {
            window['mapCH_' + mapid].remove();
            delete window['mapCH_' + mapid];
        }
    };
};


export const createBalloonWithIcon = (balloon) => {
    const color = balloonColors[balloon.BalloonColor];
    const pattern = colorPattern[balloon.Pattern][balloon.PatternColor];

    const icon = L.divIcon({
        className: 'custom-icon',
        html: `<div style="position: relative; width: 70px; height: 70px; z-index: 999;">
               <img src="${color}" style="position: absolute; top: 0; left: -28px; width: 100%; height: 100%;" />
               <img src="${pattern}" style="position: absolute; top: 7px; left: -28px; width: 100%; height: 50%;" />
               </div>`
    });

    return { ...balloon, icon };
};

const BalloonMap = () => {
    const [zoomLevel, setZoomLevel] = useState(13);
    const [balloons, setBalloons] = useState([]);
    const [markers, setMarkers] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [selectedBalloonLoaded, setSelectedBalloonLoaded] = useState(false);
    const [mainBalloon, setMainBalloon] = useState(null);
    const [eventLocation, setEventLocation] = useState(null);
    const { eventId } = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        // Initialize the map
        initializeMap(zoomLevel, 'mapid');
        fetchData();
        fetchEventLocation()
        const intervalId = setInterval(fetchData, 30000); // Fetch data every 30 seconds

        // Cleanup function
        return () => {
            clearInterval(intervalId);
            // Remove the map when the component is unmounted
            if (window['mapCH_mapid']) {
                window['mapCH_mapid'].remove();
                delete window['mapCH_mapid'];
            }
        };
    }, []);


    useEffect(() => {
        if (dataLoaded && balloons.length > 0 && eventLocation) {
            updateMapWithBalloonData();
        }
    }, [dataLoaded, balloons, eventLocation]);


    useEffect(() => {
        updateMarkers();
    }, [balloons]);


    const fetchEventLocation = () => {
        fetchWithHeaders(`/api/event_table/${eventId}/location`, { method: "GET" })
            .then(response => response.json())
            .then(data => {
                const location = [data.y, data.x];
                setEventLocation(location);
                L.marker(location, { icon: customIcon }).addTo(window[map]);
            });
    };

    const fetchData = () => {
        fetchWithHeaders(`/api/event_table/${eventId}/balloons`, { method: "GET" })
            .then(response => response.json())
            .then(data => {
                const newBalloons = data.map(balloon => createBalloonWithIcon(balloon));
                setBalloons(newBalloons);
                setDataLoaded(true);
            });
    };

    const updateMapWithBalloonData = () => {
        const uniqueName = Cookies.get("UniqueName");
        fetchBalloonData(uniqueName);
        const selectedBalloon = balloons.find(balloon => balloon.name === uniqueName);
        const newView = selectedBalloon
            ? [selectedBalloon.longitude, selectedBalloon.latitude]
            : eventLocation;
        window['mapCH_mapid'].setView(newView, zoomLevel);

        if(selectedBalloon?.height < 0) {
           navigateToEnd();
        }

        setSelectedBalloonLoaded(true);
    };

const fetchBalloonData = (uniqueName, retryCount = 0) => {
    fetchWithHeaders(`/api/balloon/?name=${uniqueName}`, { method: "GET" })
        .then(response => {
            if (response.status === 404){
                if (retryCount < 3) { // Maximum of 3 retries
                    // Wait for 1 seconds before retrying
                    setTimeout(() => fetchBalloonData(uniqueName, retryCount + 1), 1000);
                } else {
                    navigate(`/`);
                }
            } else {
                return response.json();
            }
        })
        .then(data => {
            if (data && data.length > 0) {
                const selectedBalloon = { ...data[0], name: uniqueName };
                setMainBalloon(selectedBalloon);
            }
        }).catch(error => console.error(error));
};

    const updateMarkers = () => {
        if (balloons.length > 0 && window[map]) {
            markers.forEach(marker => window[map].removeLayer(marker));

            const newMarkers = balloons.map(balloon => {
                const marker = L.marker([balloon.longitude, balloon.latitude], { icon: balloon.icon })
                    .addTo(window[map]);
                marker.bindPopup(`<b>${balloon.name}</b>`);
                return marker;
            });

            setMarkers(newMarkers);
        }
    };

    const navigateToEnd = () => {
        navigate(`/${eventId}/stats`)
    }

    const getWindDirectionArrowStyle = (wind) => {
        return {
            transform: `rotate(${wind-115}deg)`,
        };
    };

    return (
        <div className="container">
            <Header />
            <div id="mapid" data-testid="mapid"></div>
            {selectedBalloonLoaded  && (
                <>
                    <div id="wind-info" data-testid="wind-info">
                        <span className="wind-arrow" style={getWindDirectionArrowStyle(mainBalloon?.windDirection)}>&#8593;</span>
                    </div>
                    <div id="height-info" data-testid="height-info">{Math.floor(mainBalloon?.height)} m</div>

                    <div className="text-container">
                        <br/>
                    <h2>Ihr Ballon {mainBalloon?.name} ist unterwegs</h2>
                        <SubmitButton isFormValid={true} onClick={navigateToEnd}>Statistik</SubmitButton>
                    </div>
                </>
            )}
        </div>
    );
};

export default BalloonMap;
