import TrianglePatternStandard from '../../patterns/PatternTriangles.svg'
import TrianglePattern1 from './Triangle1.svg'
import TrianglePattern2 from './Triangle2.svg'
import TrianglePattern3 from './Triangle3.svg'
import TrianglePattern4 from './Triangle4.svg'
import TrianglePattern5 from './Triangle5.svg'
import TrianglePattern6 from './Triangle6.svg'
import TrianglePattern7 from './Triangle7.svg'
import TrianglePattern8 from './Triangle8.svg'
import TrianglePattern9 from './Triangle9.svg'

export const trianglePatterns = {
    standard: TrianglePatternStandard,
    B1: TrianglePattern1,
    B2: TrianglePattern2,
    B3: TrianglePattern3,
    B4: TrianglePattern4,
    B5: TrianglePattern5,
    B6: TrianglePattern6,
    B7: TrianglePattern7,
    B8: TrianglePattern8,
    B9: TrianglePattern9
};