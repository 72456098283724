import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import '../../assets/styles/AirPressStyles.css'
import {useNavigate, useParams} from "react-router-dom";
import {balloon, bgColors} from "../../components/utils/utils";
import {balloonColors} from "../../assets/colors/Colors";
import heliumPump from '../../assets/imgs/Helium_Pump.jpg'
import poppingAnimation from '../../assets/imgs/Popping_Animation.gif'
import transitionGIF from '../../assets/imgs/balloonAnimation.gif'
import Cookies from "js-cookie";
import {fetchWithHeaders} from "../../components/utils/api";

const AirPress = () => {
    let navigate = useNavigate();
    const { eventId } = useParams();
    const selectedBalloon = balloonColors[balloon.color] || balloonColors.standard;
    const [selectedPattern] = useState(balloon.colorPattern);
    const [scale, setScale] = useState(0.5);
    const [isInflating, setIsInflating] = useState(false);
    const [showGif, setShowGif] = useState(false);
    const [showTransitionGif, setShowTransitionGif] = useState(false);
    const [showModal, setShowModal] = useState(true);

    useEffect(() => {
        let intervalId;
        if (isInflating) {
            intervalId = setInterval(() => {
                setScale(currentScale => {
                    if (currentScale >= 1.5) {
                        clearInterval(intervalId);
                        setShowGif(true);
                        setTimeout(() => {
                            setScale(0);
                            setTimeout(() => {
                                setShowGif(false);
                                setTimeout(() => {
                                    setIsInflating(false);
                                    setScale(0.5);
                                }, 300);
                            }, 1000);
                        }, 500);
                        return currentScale;
                    }
                    return currentScale + 0.01;
                });
            }, 50);
        } else {
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [isInflating]);

    const toggleInflation = () => {
        if (!showGif) {
            setIsInflating(!isInflating);
        }
    };

    const navigateToMap = async () => {
        setShowTransitionGif(true);
        setTimeout(() => {
            try {
                const radius = (scale - 0.5) / (1.5 - 0.5) * (15 - 12) + 12;
                const balloonData = {
                    color: Cookies.get('selectedColor'),
                    pattern: Cookies.get('selectedPattern'),
                    colorPattern: Cookies.get('selectedColorPattern'),
                    name: Cookies.get('UniqueName').trim(),
                    owner: Cookies.get('email'),
                    eventId: eventId,
                    longitude: Cookies.get('longitude'),
                    latitude: Cookies.get('latitude'),
                    radius: radius
                };
                console.log(balloonData);
                fetchWithHeaders('/api/balloon/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(balloonData)
                })
                    .then(response => {
                        if (!response.ok) {
                            if(response.status === 400) {
                                alert("Error: A balloon has already been launched from this account.");
                                navigate('/');
                            }
                        }
                        return response.text();
                    })
                    .then(data => {
                        if (Cookies.get('UniqueName') !== data) {
                            Cookies.set('UniqueName', data);
                        }
                        navigate(`/${eventId}/map`);
                    })
            } catch (error) {
                console.error(error);
            }
        }, 2000);
    };

    return (
        <div>
            <Header />

            {showTransitionGif && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={transitionGIF} alt="Loading Animation" style={{
                        width: '100%',
                        height: '100%'
                    }} />
                </div>
            )}

            {showModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        textAlign: 'center',
                        maxWidth: '80%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                    }}>
                        <p>Klicken Sie auf die Heliumflasche um den Ballon aufzublasen. Klicken Sie erneut um zu stoppen.</p>
                        <button
                            onClick={() => setShowModal(false)}
                            style={{
                                backgroundColor: bgColors.LightBlue,
                                border: 'none',
                                borderRadius: '5px',
                                color: 'white',
                                padding: '10px 20px',
                                fontSize: '16px',
                                cursor: 'pointer',
                                marginTop: '10px'
                            }}
                        >
                            Verstanden
                        </button>
                    </div>
                </div>
            )}

            <p className="info-paragraph">Klicken Sie auf die Heliumflasche um den Ballon aufzublasen. Klicken Sie erneut um zu stoppen.</p>

            <div style={{
                position: 'absolute',
                left: '50%',
                top: '35%',
                transform: `translate(-50%, -50%) scale(${scale})`,
                transition: 'transform 0.5s'
            }}>
                {!showGif && <img
                    src={selectedBalloon}
                    alt="Selected Balloon"
                    style={{
                        maxWidth: '100%',
                        height: 'auto'
                    }}
                />}
                {selectedPattern && !showGif && (
                    <img
                        src={selectedPattern}
                        alt="Selected Pattern"
                        style={{
                            maxWidth: '100%',
                            height: 'auto',
                            position: 'absolute',
                            left: "25%",
                            top: "10%",
                            width: '48%'
                        }}
                    />
                )}
                {showGif && (
                    <img
                        src={poppingAnimation}
                        alt="Balloon Pop Animation"
                        style={{
                            maxWidth: '100%',
                            height: 'auto'
                        }}
                    />
                )}
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                top: '30%',
                transform: 'scale(0.3)'
            }}>
                <img
                    onClick={toggleInflation}
                    src={heliumPump}
                    alt="Helium Pump"
                />
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                width: '100%',
                top: '82vh'
            }}>
                <button
                    type="button"
                    onClick={navigateToMap}
                    style={{
                        width: "200px",
                        height: "65px",
                        backgroundColor: scale <= 0.5 || showGif ? '#d5d5d5' : "white",
                        border: `3px solid ${bgColors.LightBlue}`,
                        borderRadius: "10px",
                        color: "darkBlue",
                        fontSize: "25px",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                        cursor: scale <= 0.5 || showGif ? "not-allowed" : "pointer"
                    }}
                    disabled={scale <= 0.5 || showGif}
                >
                    Starten!
                </button>
            </div>
        </div>
    );
}

export default AirPress;
