// UserForm.js
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { fetchWithHeaders ,  baseURL } from "../utils/api";


const UserForm = ({ onRegister, checked, handleChange }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [street, setStreet] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [location, setLocation] = useState('');

    const isEmailUnique = async (emailToCheck) => {
        const response = await fetchWithHeaders(`/api/User_table/isEmailUnique?email=${emailToCheck}`);
        const result = await response.json();
        return result.isUnique;
    };

    function validateCredentials(name, street, postalCode, location, email) {
        // Regex patterns
        const nameFormat = /^[a-zA-Z- ]+$/;
        const streetFormat = /^[a-zA-Z0-9- ]+$/;
        const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        // Concatenate street, postalCode, and location to form an address
        const address = street + postalCode + location;

        // Check if any field is empty
        if (name.trim() === '' || street.trim() === '' || postalCode.trim() === '' || location.trim() === '' || email.trim() === '') {
            throw new Error('The string is not allowed to be empty');
        }

        // Check name format
        if (!nameFormat.test(name)) {
            throw new Error('This name is not valid: ' + name);
        }

        // Check address format
        if (!streetFormat.test(address)) {
            throw new Error('The given address is invalid: ' + address);
        }

        // Check email format
        if (!emailFormat.test(email)) {
            throw new Error('This email is invalid');
        }
    }

    const handleRegister = async () => {
        // Validate form fields before proceeding
        try {
            validateCredentials(name, street, postalCode, location, email);
        } catch (error) {
            alert(error.message);
            return;
        }



        // Check if the email is unique
        const emailIsUnique = await isEmailUnique(email);
        if (!emailIsUnique) {
            alert('Email is already registered. Please enter a different email address.');
            return;
        }

        // Continue with registration since all checks passed
        // Create user object
        const user = {
            name,
            email,
            street,
            postalCode,
            location,
            events: [], // Assuming events are not captured in the form, initialize as an empty list
        };

        // Call the onRegister function with the user object
        onRegister(user, email);
    };



    return (
        <Paper elevation={3} style={{ width: '300px', padding: '20px', margin:'10px'}}>
            <h1 style={{ color: 'blue', textAlign: 'center' }}>User Registration</h1>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="outlined-basic-name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <TextField
                    id="outlined-basic-email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                    id="outlined-basic-street"
                    label="Street"
                    variant="outlined"
                    fullWidth
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                />

                <TextField
                    id="outlined-basic-postalCode"
                    label="Postal Code"
                    variant="outlined"
                    fullWidth
                    value={postalCode}
                    type="number"
                    onChange={(e) => setPostalCode(e.target.value)}
                />

                <TextField
                    id="outlined-basic-location"
                    label="Location"
                    variant="outlined"
                    fullWidth
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                />

                <label>
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={handleChange}
                    />
                    AGB gelesen
                </label>

                <Button variant="contained" onClick={handleRegister} disabled={!checked} sx={{ marginTop: 2 }}>
                    Register
                </Button>
            </Box>
        </Paper>
    );
};

export default UserForm;
