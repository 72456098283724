import * as React from 'react';
import { createTheme } from '@mui/material/styles';
import Registration from "./pages/registration/Registration";
import LandingPage from "./pages/registration/LandingPage";
import EndingPage from './pages/MapPage/EndingPage';
import Verification from "./pages/registration/Verification";
import Naming from "./pages/balloon_config/Naming";
import ColorCustomizer from "./pages/balloon_config/ColorCustomizer";
import AirPress from "./pages/balloon_config/AirPress";
import Pattern from "./pages/balloon_config/Pattern";
import PatternColor from "./pages/balloon_config/PatternColor";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PrivateRoute from "./pages/organizer/organizerLogin/PrivateRoute";
import OrganizerExplorer from "./pages/organizer/organizerDashboard/OrganizerExplorer";
import OrganizerLogin from "./pages/organizer/organizerLogin/OrganizerLogin";
import {AuthProvider} from "./pages/organizer/organizerLogin/AuthContext";
import BalloonMap from "./pages/MapPage/BalloonMap";
import BalloonMapPublic from "./pages/MapPage/BalloonMapPublic";
import Balloon from "./components/old_for_debug/Balloon";
import Editor from "./pages/organizer/organizerDashboard/Editor";
import Archive from "./pages/organizer/organizerDashboard/Archive";
import { useParams } from "react-router-dom";
import { useEffect } from 'react'
import {AccordionHeader} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import NotFoundPage  from './pages/errorPage/NotFoundPage';
import IDContext from './components/utils/IDContext';
import { useState } from 'react';
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";


function RedirectToEvent() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/1/');
    }, [navigate]);

    return null;
}

function App() {
    const { eventId } = useParams();
    const [userID, setUserID] = useState(null);
    const [balloonID, setBalloonID] = useState(null);

    return (
        <IDContext.Provider value={{ userID, setUserID, balloonID, setBalloonID }}>
            <BrowserRouter>
                <AuthProvider>
                <Routes>
                    <Route path="/" element={<RedirectToEvent />} />
                    <Route exact path="/:eventId/" element={<LandingPage eventId={eventId} />} />
                    <Route exact path="/:eventId/stats" element={<EndingPage />} />
                    <Route exact path="/:eventId/registration" element={<Registration eventId={eventId} />} />
                    <Route exact path="/:eventId/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/404" element={<NotFoundPage />} />
                    <Route path="/:eventId/verification" element={<Verification />} />
                    <Route path="/:eventId/naming" element={<Naming />} />
                    <Route path="/:eventId/color" element={<ColorCustomizer />} />
                    <Route path="/:eventId/pattern" element={<Pattern />} />
                    <Route path="/:eventId/patternColor" element={<PatternColor />} />
                    <Route path="/:eventId/airPress" element={<AirPress />} />
                    <Route path="/:eventId/overview" element={<BalloonMapPublic />} />
                    <Route path="/:eventId/map" element={<BalloonMap />} />
                    <Route path="/balloon/*" element={<Balloon />} />
                    <Route path="/login" element={<OrganizerLogin />} />
                    <Route path="/dashboard" element={<PrivateRoute><OrganizerExplorer/></PrivateRoute>}/>
                    <Route path="/editor" element={<Editor />} />
                    <Route path="/archive" element={<Archive />} />
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </IDContext.Provider>
    );
}

export default App;