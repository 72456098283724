import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import { balloonColors } from '../../assets/colors/Colors';
import {balloon, bgColors} from '../../components/utils/utils';
import { colorPattern } from '../../assets/full_balloon_pattern/patternColor';
import { buttonColors } from '../../assets/colors/Colors';
import Cookies from "js-cookie";

import {circlePatterns} from "../../assets/full_balloon_pattern/circles/CirclePatterns";
import {starPatterns} from "../../assets/full_balloon_pattern/stars/StarPatterns";
import {trianglePatterns} from "../../assets/full_balloon_pattern/triangles/TrianglePatterns";
import {rectanglePatterns} from "../../assets/full_balloon_pattern/rectangles/RectanglePatterns";

const patternMapping = {
    circlePatterns,
    starPatterns,
    trianglePatterns,
    rectanglePatterns
};

const PatternColor = () => {
    let navigate = useNavigate();
    const { eventId } = useParams();
    const [selectedPattern, setSelectedPattern] = useState(balloon.pattern);
    const [selectedColorPattern, setSelectedColorPattern] = useState('');
    const [patternColor, setPatternColor] = useState('');
    const patternColorArr = colorPattern[balloon.patternName];
    const selectedBalloon = balloonColors[balloon.color] || balloonColors.standard;

    useEffect(() => {
        const savedColorPattern = Cookies.get('selectedColorPattern');
        if (savedColorPattern) {
            setSelectedColorPattern(savedColorPattern);
            setPatternColor(patternColorArr[savedColorPattern]);
            balloon.colorPattern = patternColorArr[savedColorPattern];
        }
    }, [patternColorArr]);

    const handlePatternColorChange = (colorName) => {
        setSelectedColorPattern(colorName);
        const colorValue = patternColorArr[colorName];
        setPatternColor(colorValue);
        balloon.colorPattern = colorValue;
        Cookies.set('selectedColorPattern', colorName, { expires: 7 });
    };

    const getPatternImage = () => {
        const patternSet = patternMapping[balloon.patternName];
        if (patternSet) {
            return patternSet[selectedColorPattern];
        }
        return selectedPattern;
    };

    const navigateToAirPress = async () => {
        try {
            navigate(`/${eventId}/airPress`);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div style={{ position: 'relative', height: '100%' }}>
            <Header />

            <img
                src={selectedBalloon}
                alt="Selected Balloon"
                style={{
                    maxWidth: '100%',
                    height: 'auto',
                    position: 'absolute',
                    left: '50%',
                    top: '400%',
                    transform: 'translate(-50%, -50%)',
                }}
            />

            {selectedPattern && (
                <img
                    src={getPatternImage()}
                    alt="Selected Pattern"
                    style={{
                        maxWidth: '100%',
                        height: 'auto',
                        position: 'absolute',
                        left: '49%',
                        top: '320%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: patternColor,
                        mixBlendMode: 'normal',
                        filter: `opacity(${patternColor ? 1 : 0})`
                    }}
                />
            )}

            <div
                style={{
                    position: 'absolute',
                    left: '50%',
                    top: '52vh',
                    transform: 'translateX(-50%)',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '260px',
                    margin: '10px auto',
                }}
            >
                {Object.entries(buttonColors).map(([colorName, colorValue]) => (
                    <button
                        key={colorName}
                        onClick={() => handlePatternColorChange(colorName)}
                        style={{
                            backgroundColor: selectedColorPattern === colorName ? '#D3D3D3' : 'transparent',
                            width: "80px",
                            height: '80px',
                            border: '3px solid #62B4EA',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: '4px 4px 5px rgba(0, 0, 0, 0.3)',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: colorValue,
                                width: '55px',
                                height: '55px',
                                border: '3px solid black',
                                borderRadius: '50%',
                            }}
                        ></div>
                    </button>
                ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', width: '100%', top: '88vh' }}>
                <button
                    type="button"
                    onClick={navigateToAirPress}
                    style={{
                        width: "200px",
                        height: "65px",
                        backgroundColor: "white",
                        border: `3px solid ${bgColors.LightBlue}`,
                        borderRadius: "10px",
                        color: "darkBlue",
                        fontSize: "25px",
                        fontFamily: "Inter",
                        fontWeight: "bold",
                        boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                    }}
                    disabled={!selectedColorPattern}
                >
                    Weiter
                </button>
            </div>
        </div>
    );
};

export default PatternColor;
