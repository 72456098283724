import logoBalloon from '../assets/imgs/Logo_PocketBalloon.jpg';
import logoBrand from '../assets/imgs/aargausolothurn_farb.jpg';

const Header = () => {
  const imageContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      minWidth: "100%"
  };

  const imageStyleBalloon = {
      margin: "1%"
  };

  const imageStyleLogo = {
      margin: "1%"
  }

  return (
      <div style={imageContainerStyle}>
          <img src={logoBrand} style={{...imageStyleLogo, height: "36px", width: "auto"}} alt="logoBrand"/>
          <img src={logoBalloon} style={{...imageStyleBalloon, height: "57px", width: "auto"}} alt="logoBalloon"/>
      </div>
  );

}

export default Header;