import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div style={{ padding: '1%', maxWidth: '95vw', margin: 'auto' }}>
            <h1 id="top" style={{fontSize: '5vh'}}>Datenschutzerklärung</h1>
            <p>Mit dieser <strong>Datenschutzerklärung</strong> informieren wir, welche Personendaten wir im Zusammenhang mit unseren <strong>Aktivitäten und Tätigkeiten</strong> einschliesslich unserer <strong><nobr>www.aargaufreizeit.ch</nobr>-Website</strong> bearbeiten. Wir informieren insbesondere, wofür, wie und wo wir welche Personendaten bearbeiten. Wir informieren ausserdem über die Rechte von Personen, deren Daten wir bearbeiten.</p>
            <p>Für einzelne oder zusätzliche Aktivitäten und Tätigkeiten können weitere Datenschutzerklärungen sowie sonstige rechtliche Dokumente wie Allgemeine Geschäftsbedingungen (AGB), Nutzungsbedingungen oder Teilnahmebedingungen gelten.</p>
            <h2 id="kontaktadressen">1. Kontaktadressen</h2>
            <p>Verantwortung für die Bearbeitung von Personendaten:</p>
            <p><strong id="verantwortlicher">Holger Czerwenka</strong><br />
                Laurstrasse 10<br />
                5200 Brugg</p>
            <p><a href="mailto:holger.czerwenka@aargautourismus.ch">holger.czerwenka@aargautourismus.ch</a></p>
            <p>Im Einzelfall kann es andere Verantwortliche für die Bearbeitung von Personendaten oder eine gemeinsame Verantwortlichkeit mit mindestens einem anderen Verantwortlichen geben.</p>
            <h3 id="datenschutzberater">Datenschutzberaterin oder Datenschutzberater</h3>
            <p>Wir verfügen über die nachfolgende Datenschutzberaterin oder den nachfolgenden Datenschutzberater als Anlaufstelle für betroffene Personen und Behörden bei Anfragen im Zusammenhang mit dem Datenschutz:</p>
            <p><strong>Holger Czerwenka</strong><br />
                Laurstrasse 10<br />
                5200 Brugg</p>
            <p><a href="mailto:holger.czerwenka@aargautourismus.ch">holger.czerwenka@aargautourismus.ch</a></p>
            <h2 id="begriffe-rechtsgrundlagen">2. Begriffe und Rechtsgrundlagen</h2>
            <h3 id="begriffe">2.1 Begriffe</h3>
            <p><strong>Personendaten</strong> sind <em>alle</em> Angaben, die sich auf eine bestimmte oder bestimmbare natürliche Person beziehen.</p>
            <p><strong>Besonders schützenswerte Personendaten</strong> sind Daten über gewerkschaftliche, politische, religiöse oder weltanschauliche Ansichten und Tätigkeiten, Daten über die Gesundheit, die Intimsphäre oder die Zugehörigkeit zu einer Ethnie oder Rasse, genetische Daten, biometrische Daten, die eine natürliche Person eindeutig identifizieren, Daten über straf- und verwaltungsrechtliche Sanktionen oder Verfolgungen, und Daten über Massnahmen der sozialen Hilfe.</p>
            <p><strong>Bearbeiten</strong> umfasst <em>jeden</em> Umgang mit Personendaten, <em>unabhängig</em> von den angewandten Mitteln und Verfahren, beispielsweise das Abfragen, Abgleichen, Anpassen, Archivieren, Aufbewahren, Auslesen, Bekanntgeben, Beschaffen, Erfassen, Erheben, Löschen, Offenlegen, Ordnen, Organisieren, Speichern, Verändern, Verbreiten, Verknüpfen, Vernichten und Verwenden von Personendaten.</p>
            <p>Eine <strong>betroffene Person</strong> ist eine natürliche Person, über die wir Personendaten bearbeiten.</p>
            <h3 id="rechtsgrundlagen">2.2 Rechtsgrundlagen</h3>
            <p>Wir bearbeiten Personendaten im Einklang mit dem schweizerischen Datenschutzrecht wie insbesondere dem <a href="https://www.fedlex.admin.ch/eli/oc/2022/491/de" rel="nofollow noopener noreferrer" target="_blank" metadata="150681-1716894208">Bundesgesetz über den Datenschutz</a> (Datenschutzgesetz, DSG) und der <a href="https://www.fedlex.admin.ch/eli/oc/2022/568/de" rel="nofollow noopener" target="_blank">Verordnung über den Datenschutz</a> (Datenschutzverordnung, DSV).</p>
            <h2 id="art-umfang-zweck">3. Art, Umfang und Zweck</h2>
            <p>Wir bearbeiten jene Personendaten, die <em>erforderlich</em> sind, um unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und zuverlässig ausüben zu können. Solche Personendaten können insbesondere in die Kategorien von Bestandes- und Kontaktdaten, Browser- und Gerätedaten, Inhaltsdaten, Meta- bzw. Randdaten und Nutzungsdaten, Standortdaten, Verkaufsdaten sowie Vertrags- und Zahlungsdaten fallen.</p>
            <p>Wir bearbeiten Personendaten während jener <em>Dauer</em>, die für den jeweiligen Zweck bzw. die jeweiligen Zwecke oder gesetzlich erforderlich ist. Personendaten, deren Bearbeitung nicht mehr erforderlich ist, werden anonymisiert oder gelöscht.</p>
            <p>Wir können Personendaten <em>durch Dritte</em> bearbeiten lassen. Wir können Personendaten gemeinsam mit Dritten bearbeiten oder an Dritte übermitteln. Bei solchen Dritten handelt es sich insbesondere um spezialisierte Anbieter, deren Leistungen wir in Anspruch nehmen. Wir gewährleisten auch bei solchen Dritten den Datenschutz.</p>
            <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> nur mit Einwilligung der betroffenen Personen. Sofern und soweit die Bearbeitung aus anderen rechtlichen Gründen zulässig ist, können wir darauf verzichten, eine Einwilligung einzuholen. Wir können Personendaten beispielsweise ohne Einwilligung bearbeiten, um einen Vertrag zu erfüllen, um rechtlichen Verpflichtungen nachzukommen oder um überwiegende Interessen zu wahren.</p>
            <p>Wir bearbeiten ausserdem Personendaten, die wir von Dritten erhalten, aus öffentlich zugänglichen Quellen beschaffen oder bei der Ausübung unserer Aktivitäten und Tätigkeiten erheben, sofern und soweit eine solche Bearbeitung aus rechtlichen Gründen zulässig ist.</p>
            <h2 id="kommunikation">4. Kommunikation</h2>
            <p>Wir bearbeiten Personendaten, um mit Dritten kommunizieren zu können. Wir bearbeiten in diesem Rahmen insbesondere Daten, die eine betroffene Person bei der Kontaktaufnahme übermittelt, zum Beispiel per Briefpost oder E-Mail. Wir können solche Daten in einem Adressbuch oder mit vergleichbaren Hilfsmitteln speichern.</p>
            <p>Dritte, die Daten über andere Personen übermitteln, sind verpflichtet, den Datenschutz gegenüber solchen betroffenen Personen zu gewährleisten. Dafür muss unter anderem die Richtigkeit der übermittelten Personendaten sichergestellt werden.</p>
            <p>Wir nutzen ausgewählte Dienste von geeigneten Anbietern, um mit Dritten besser kommunizieren zu können.</p>
            <p>Wir nutzen insbesondere:</p>
            <ul>
                <li><strong><a href="https://www.bexio.com/" rel="nofollow noopener noreferrer" target="_blank">bexio:</a></strong> Customer-Relationship-Management (CRM); Anbieterin: bexio AG (Schweiz); Angaben zum Datenschutz: <a href="https://www.bexio.com/de-CH/richtlinien/datenschutz" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://www.bexio.com/de-CH/cloud" rel="nofollow noopener noreferrer" target="_blank">«Cloud und Datensicherheit»</a>, <a href="https://www.bexio.com/de-CH/blog/view/datensicherheit" rel="nofollow noopener noreferrer" target="_blank">«Datensicherheit – Definition und Massnahmen für Unternehmen»</a>.
                </li>
            </ul>
            <h2 id="sicherheit">5. Datensicherheit</h2>
            <p>Wir treffen geeignete technische und organisatorische Massnahmen, um eine dem jeweiligen Risiko angemessene Datensicherheit zu gewährleisten. Mit unseren Massnahmen gewährleisten wir insbesondere die Vertraulichkeit, Verfügbarkeit, Nachvollziehbarkeit und Integrität der bearbeiteten Personendaten, ohne aber absolute Datensicherheit gewährleisten zu können.</p>
            <p>Der Zugriff auf unsere Website und unsere sonstige Online-Präsenz erfolgt mittels Transportverschlüsselung (SSL&nbsp;/ TLS, insbesondere mit dem Hypertext Transfer Protocol Secure, abgekürzt HTTPS). Die meisten Browser kennzeichnen Transportverschlüsselung mit einem kleinen Vorhängeschloss in der Adressleiste.</p>
            <p>Unsere digitale Kommunikation unterliegt&nbsp;– wie <em>grundsätzlich</em> jede digitale Kommunikation&nbsp;– der Massenüberwachung ohne Anlass und Verdacht durch Sicherheitsbehörden in der Schweiz, im übrigen Europa, in den Vereinigten Staaten von Amerika&nbsp;(USA) und in anderen Ländern. Wir können keinen direkten Einfluss auf die entsprechende Bearbeitung von Personendaten durch Geheimdienste, Polizeistellen und andere Sicherheitsbehörden nehmen. Wir können auch nicht ausschliessen, dass einzelne betroffene Personen gezielt überwacht werden.</p>
            <h2 id="ausland">6. Personendaten im Ausland</h2>
            <p>Wir bearbeiten Personendaten <em>grundsätzlich</em> in der Schweiz. Wir können Personendaten aber auch in andere Staaten bekanntgeben bzw. exportieren, insbesondere um sie dort zu bearbeiten oder bearbeiten zu lassen.</p>
            <p>Wir können Personendaten in alle <a href="https://de.wikipedia.org/wiki/Liste_der_Staaten_der_Erde#Liste" rel="nofollow noopener noreferrer" target="_blank">Staaten und Territorien auf der Erde</a> sowie anderswo im <a href="https://www.datenschutzpartner.ch/2022/04/23/datenschutzerklaerung-daten-export-universum-mond/" rel="nofollow noopener noreferrer" target="_blank">Universum</a> bekanntgeben, sofern das dortige Recht gemäss <a href="https://steigerlegal.ch/2022/09/10/ndsg-schweiz-staatenliste/" rel="nofollow noopener noreferrer" target="_blank">Beschluss des Schweizerischen Bundesrates</a> einen angemessenen Datenschutz gewährleistet.</p>
            <p>Wir können Personendaten in Staaten, deren Recht keinen angemessenen Datenschutz gewährleistet, bekanntgeben, sofern aus anderen Gründen ein geeigneter Datenschutz gewährleistet ist, insbesondere auf Grundlage von Standard­datenschutzklauseln oder mit anderen geeigneten Garantien. Ausnahmsweise können wir Personendaten in Staaten ohne angemessenen oder geeigneten Datenschutz exportieren, wenn dafür die besonderen datenschutz­rechtlichen Voraussetzungen erfüllt sind, beispielsweise die ausdrückliche Einwilligung der betroffenen Personen oder ein unmittelbarer Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrages. Wir geben betroffenen Personen auf Nachfrage gerne Auskunft über allfällige Garantien oder liefern eine Kopie von Garantien.</p>
            <h2 id="rechte">7. Rechte von betroffenen Personen</h2>
            <h3 id="ansprueche">7.1 Datenschutzrechtliche Ansprüche</h3>
            <p>Wir gewähren betroffenen Personen sämtliche Ansprüche gemäss dem anwendbaren Datenschutzrecht. Betroffene Personen verfügen insbesondere über folgende Rechte:</p>
            <ul>
                <li><strong>Auskunft:</strong> Betroffene Personen können Auskunft verlangen, ob wir Personendaten über sie bearbeiten, und falls ja, um welche Personendaten es sich handelt. Betroffene Personen erhalten ferner jene Informationen, die erforderlich sind, um ihre datenschutzrechtlichen Ansprüche geltend zu machen und Transparenz zu gewährleisten. Dazu zählen die bearbeiteten Personendaten als solche, aber unter anderem auch Angaben zum Bearbeitungszweck, zur Dauer der Aufbewahrung, zu einer allfälligen Bekanntgabe bzw. einem allfälligen Export von Daten in andere Staaten und zur Herkunft der Personendaten.</li>
                <li><strong>Berichtigung und Einschränkung:</strong> Betroffene Personen können unrichtige Personendaten berichtigen, unvollständige Daten vervollständigen und die Bearbeitung ihrer Daten einschränken lassen.</li>
                <li><strong>Löschung und Widerspruch:</strong> Betroffene Personen können Personendaten löschen lassen («Recht auf Vergessen») und der Bearbeitung ihrer Daten mit Wirkung für die Zukunft widersprechen.</li>
                <li><strong>Datenherausgabe und Datenübertragung:</strong> Betroffene Personen können die Herausgabe von Personendaten oder die Übertragung ihrer Daten an einen anderen Verantwortlichen verlangen.</li>
            </ul>
            <p>Wir können die Ausübung der Rechte von betroffenen Personen im rechtlich zulässigen Rahmen aufschieben, einschränken oder verweigern. Wir können betroffene Personen auf allenfalls zu erfüllende Voraussetzungen für die Ausübung ihrer datenschutzrechtlichen Ansprüche hinweisen. Wir können beispielsweise die Auskunft mit Verweis auf Geschäftsgeheimnisse oder den Schutz anderer Personen ganz oder teilweise verweigern. Wir können beispielsweise auch die Löschung von Personendaten mit Verweis auf gesetzliche Aufbewahrungspflichten ganz oder teilweise verweigern.</p>
            <p>Wir können für die Ausübung der Rechte <em>ausnahmsweise</em> Kosten vorsehen. Wir informieren betroffene Personen vorgängig über allfällige Kosten.</p>
            <p>Wir sind verpflichtet, betroffene Personen, die Auskunft verlangen oder andere Rechte geltend machen, mit angemessenen Massnahmen zu identifizieren. Betroffene Personen sind zur Mitwirkung verpflichtet.</p>
            <h3 id="rechtsschutz">7.2 Rechtsschutz</h3>
            <p>Betroffene Personen haben das Recht, ihre datenschutz­rechtlichen Ansprüche auf dem Rechtsweg durchzusetzen oder Anzeige bzw. Beschwerde bei einer zuständigen Datenschutz-Aufsichtsbehörde zu erheben.</p>
            <p>Datenschutz-Aufsichtsbehörde für Anzeigen von betroffenen Personen gegen private Verantwortliche und Bundesorgane in der Schweiz ist der <a href="https://www.edoeb.admin.ch/" rel="nofollow noopener noreferrer" target="_blank" metadata="150681-1716894208">Eidgenössische Datenschutz- und Öffentlichkeits­beauftragte</a>&nbsp;(EDÖB).</p>
            <h2 id="website">8. Nutzung der Website</h2>
            <h3 id="cookies">8.1 Cookies</h3>
            <p>Wir können Cookies verwenden. Bei Cookies&nbsp;– eigenen Cookies (First-Party-Cookies) als auch Cookies von Dritten, deren Dienste wir nutzen (Third-Party-Cookies)&nbsp;– handelt es sich um Daten, die im Browser gespeichert werden. Solche gespeicherten Daten müssen nicht auf traditionelle Cookies in Textform beschränkt sein.</p>
            <p>Cookies können im Browser temporär als «Session Cookies» oder für einen bestimmten Zeitraum als sogenannte permanente Cookies gespeichert werden. «Session Cookies» werden automatisch gelöscht, wenn der Browser geschlossen wird. Permanente Cookies haben eine bestimmte Speicherdauer. Cookies ermöglichen insbesondere, einen Browser beim nächsten Besuch unserer Website wiederzuerkennen und dadurch beispielsweise die Reichweite unserer Website zu messen. Permanente Cookies können aber beispielsweise auch für Online-Marketing verwendet werden.</p>
            <p>Cookies können in den Browser-Einstellungen jederzeit ganz oder teilweise deaktiviert sowie gelöscht werden. Ohne Cookies steht unsere Website allenfalls nicht mehr in vollem Umfang zur Verfügung. Wir ersuchen&nbsp;– mindestens sofern und soweit erforderlich&nbsp;– aktiv um die ausdrückliche Einwilligung in die Verwendung von Cookies.</p>
            <h3 id="protokollierung">8.2 Protokollierung</h3>
            <p>Wir können für jeden Zugriff auf unsere Website und unsere sonstige Online-Präsenz mindestens nachfolgende Angaben protokollieren, sofern diese bei solchen Zugriffen an unsere digitale Infrastruktur übermittelt werden: Datum und Zeit einschliesslich Zeitzone, <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adresse</a>, Zugriffsstatus (HTTP-Statuscode), Betriebs­system einschliesslich Benutzer­oberfläche und Version, Browser einschliesslich Sprache und Version, aufgerufene einzelne Unter-Seite unserer Website einschliesslich übertragener Daten­menge, zuletzt im gleichen Browser-Fenster aufgerufene Webseite (Referer bzw. Referrer).</p>
            <p>Wir protokollieren solche Angaben, die auch Personendaten darstellen können, in Log­dateien. Die Angaben sind erforderlich, um unsere Online-Präsenz dauerhaft, nutzerfreundlich und zuverlässig bereitstellen zu können. Die Angaben sind ferner erforderlich, um die Datensicherheit gewährleisten zu können&nbsp;– auch durch Dritte oder mit Hilfe von Dritten.</p>
            <h3 id="zaehlpixel">8.3 Zählpixel</h3>
            <p>Wir können Zählpixel in unsere Online-Präsenz einbinden. Zählpixel werden auch als Web-Beacons bezeichnet. Bei Zählpixeln&nbsp;– auch von Dritten, deren Dienste wir nutzen&nbsp;– handelt es sich üblicherweise um kleine, nicht sichtbare Bilder oder in JavaScript formulierte Skripte, die beim Zugriff auf unsere Online-Präsenz automatisch abgerufen werden. Mit Zählpixeln können mindestens die gleichen Angaben wie in Log­dateien erfasst werden.</p>
            <h2 id="mitteilungen">9. Benachrichtigungen und Mitteilungen</h2>
            <p>Wir versenden Benachrichtigungen und Mitteilungen per E-Mail und über andere Kommunikationskanäle wie beispielsweise Instant Messaging oder SMS.</p>
            <h3 id="mitteilungen-statistik">9.1 Erfolgs- und Reichweitenmessung</h3>
            <p>Benachrichtigungen und Mitteilungen können Weblinks oder Zählpixel enthalten, die erfassen, ob eine einzelne Mitteilung geöffnet wurde und welche Weblinks dabei angeklickt wurden. Solche Weblinks und Zählpixel können die Nutzung von Benachrichtigungen und Mitteilungen auch personenbezogen erfassen. Wir benötigen diese statistische Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung, um Benachrichtigungen und Mitteilungen aufgrund der Bedürfnisse und Lesegewohnheiten der Empfängerinnen und Empfänger effektiv und nutzerfreundlich sowie dauerhaft, sicher und zuverlässig versenden zu können.</p>
            <h3 id="mitteilungen-einwilligung">9.2 Einwilligung und Widerspruch</h3>
            <p>Sie müssen <em>grundsätzlich</em> in die Verwendung Ihrer E-Mail-Adresse und Ihrer sonstigen Kontaktadressen einwilligen, es sei denn, die Verwendung ist aus anderen rechtlichen Gründen zulässig. Für das allfällige Einholen einer doppelt bestätigten Einwilligung können wir das «Double Opt-in»-Verfahren verwenden. Sie erhalten in diesem Fall eine Mitteilung mit Anweisungen für die doppelte Bestätigung. Wir können eingeholte Einwilligungen einschliesslich <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adresse</a> und <a href="https://de.wikipedia.org/wiki/Zeitstempel" rel="nofollow noopener noreferrer" target="_blank">Zeitstempel</a> aus Beweis- und Sicherheitsgründen protokollieren.</p>
            <p>Sie können <em>grundsätzlich</em> dem Erhalt von Benachrichtigungen und Mitteilungen wie beispielsweise Newslettern jederzeit widersprechen. Mit einem solchen Widerspruch können Sie gleichzeitig der statistischen Erfassung der Nutzung für die Erfolgs- und Reichweitenmessung widersprechen. Vorbehalten bleiben erforderliche Benachrichtigungen und Mitteilungen im Zusammenhang mit unseren Aktivitäten und Tätigkeiten.</p>
            <h3 id="mitteilungen-dienstleister">9.3 Dienstleister für Benachrichtigungen und Mitteilungen</h3>
            <p>Wir versenden Benachrichtigungen und Mitteilungen mit Hilfe von spezialisierten Dienstleistern.</p>
            <p>Wir nutzen insbesondere:</p>
            <ul>
                <li><strong><a href="https://mailchimp.com/de/" rel="nofollow noopener noreferrer" target="_blank">Mailchimp:</a></strong>
                    Kommunikationsplattform; Anbieterin: The Rocket Science Group&nbsp;LLC DBA&nbsp;Mailchimp (USA) als
                    <a href="https://www.intuit.com/legal/intuit-group-companies/" rel="nofollow noopener noreferrer" target="_blank">Tochtergesellschaft</a>
                    der Intuit&nbsp;Inc. (USA); Angaben zum Datenschutz:
                    <a href="https://www.intuit.com/privacy/statement/" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung (Intuit)</a>
                    einschliesslich «Länder- und Regionen-spezifische Bestimmungen» («Country and Region-Specific Terms»)
                <a href="https://mailchimp.com/help/mailchimp-intuit-privacy-faq/" rel="nofollow noopener noreferrer" target="_blank">«Häufig gestellte Fragen zum Datenschutz bei Mailchimp»</a>,
                <a href="https://mailchimp.com/de/help/mailchimp-european-data-transfers/" rel="nofollow noopener noreferrer" target="_blank">«Mailchimp und europäische Datenübertragungen»</a>,
                <a href="https://mailchimp.com/about/security/" rel="nofollow noopener noreferrer" target="_blank">«Sicherheit» («Security»)</a>,
                <a href="https://mailchimp.com/legal/cookies/" rel="nofollow noopener noreferrer" target="_blank">Cookie-Richtlinie</a>,
                <a href="https://mailchimp.com/privacy-rights/" rel="nofollow noopener noreferrer" target="_blank">«Datenschutzrechtliche Anfragen» («Privacy Rights Requests»)</a>,
                    <a href="https://mailchimp.com/de/legal/" rel="nofollow noopener noreferrer" target="_blank">«Rechtliche Bestimmungen»</a> </li>
            </ul>
    <h2 id="dienste">10. Dienste von Dritten</h2>
    <p>Wir nutzen Dienste von spezialisierten Dritten, um unsere Aktivitäten und Tätigkeiten dauerhaft, nutzerfreundlich, sicher und zuverlässig ausüben zu können. Mit solchen Diensten können wir unter anderem Funktionen und Inhalte in unsere Website einbetten. Bei einer solchen Einbettung erfassen die genutzten Dienste aus technisch zwingenden Gründen mindestens zeitweilig die <a href="https://de.wikipedia.org/wiki/IP-Adresse" rel="nofollow noopener noreferrer" target="_blank">IP-Adressen</a> der Nutzerinnen und Nutzer.</p>
    <p>Für erforderliche sicherheitsrelevante, statistische und technische Zwecke können Dritte, deren Dienste wir nutzen, Daten im Zusammenhang mit unseren Aktivitäten und Tätigkeiten aggregiert, anonymisiert oder pseudonymisiert bearbeiten. Es handelt sich beispielsweise um Leistungs- oder Nutzungsdaten, um den jeweiligen Dienst anbieten zu können.</p>
    <ul>
        <li><strong><a href="https://www.microsoft.com/de-ch" rel="nofollow noopener noreferrer" target="_blank">Dienste von Microsoft:</a></strong> Anbieterinnen: Microsoft Ireland Operations Limited (Irland) für Nutzerinnen und Nutzer im Europäischen Wirtschaftsraum&nbsp;(EWR), in der Schweiz und im Verereinigten Königreich&nbsp;/ Microsoft Corporation&nbsp;(USA) für Nutzerinnen und Nutzer im Rest der Welt; Allgemeine Angaben zum Datenschutz: <a href="https://privacy.microsoft.com/de-de" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz bei Microsoft»</a>, <a href="https://www.microsoft.com/de-ch/trust-center/privacy" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz und Privatsphäre»</a>, <a href="https://privacy.microsoft.com/de-de/privacystatement" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://account.microsoft.com/account/privacy?lang=de-DE" rel="nofollow noopener noreferrer" target="_blank">«Daten- und Datenschutzeinstellungen»</a>.
        </li>
    </ul>
    <h3 id="infrastruktur">10.1 Digitale Infrastruktur</h3>
    <p>Wir nutzen Dienste von spezialisierten Dritten, um benötigte digitale Infrastruktur im Zusammenhang mit unseren Aktivitäten und Tätigkeiten in Anspruch nehmen zu können. Dazu zählen beispielsweise Hosting- und Speicherdienste von ausgewählten Anbietern.</p>
    <p>Wir nutzen insbesondere:</p>
    <ul>
        <li><strong><a href="https://www.metanet.ch/de" rel="nofollow noopener noreferrer" target="_blank">METANET:</a></strong> Hosting; Anbieterin: METANET&nbsp;AG (Schweiz); Angaben zum Datenschutz: <a href="https://www.metanet.ch/de/ueber-metanet/datenschutzerklaerung" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>, <a href="https://www.metanet.ch/media/644/download/METANET_TOM_V1.1-2.pdf" rel="nofollow noopener noreferrer" target="_blank">«Technisch-organisatorische Massnahmen»</a>.
        </li>
        <li><strong><a href="https://azure.microsoft.com/de-de/" rel="nofollow noopener noreferrer" target="_blank">Microsoft Azure:</a></strong> Speicherplatz und sonstige Infrastruktur; Anbieterin: Microsoft; Microsoft Azure-spezifische Angaben: <a href="https://azure.microsoft.com/de-de/explore/trusted-cloud/privacy/" rel="nofollow noopener noreferrer" target="_blank">«Datenschutz in Azure»</a>.
        </li>
    </ul>
    <h3 id="kartenmaterial">10.2 Kartenmaterial</h3>
    <p>Wir nutzen Dienste von Dritten, um Karten in unsere Website einbetten zu können.</p>
    <p>Wir nutzen insbesondere:</p>
    <ul>
        <li><strong><a href="https://www.openstreetmap.org/" rel="nofollow noopener noreferrer" target="_blank">OpenStreetMap (OSM):</a></strong> Kartendienst; Anbieterin: OpenStreetMap Foundation (Vereinigtes Königreich); Angaben zum Datenschutz: <a href="https://wiki.osmfoundation.org/wiki/Privacy_Policy" rel="nofollow noopener noreferrer" target="_blank">Datenschutzerklärung</a>.
        </li>
    </ul>
    <h2 id="schlussbestimmungen">11. Schlussbestimmungen</h2>
    <p>Wir haben diese Datenschutzerklärung mit dem <a href="https://www.datenschutzpartner.ch/angebot-datenschutz-generator/" rel="nofollow noopener" target="_blank">Datenschutz-Generator</a> von <a href="https://datenschutzpartner.ch/" rel="nofollow noopener" target="_blank">Datenschutzpartner</a> erstellt.</p>
    <p>Wir können diese Datenschutzerklärung jederzeit anpassen und ergänzen. Wir werden über solche Anpassungen und Ergänzungen in geeigneter Form informieren, insbesondere durch Veröffentlichung der jeweils aktuellen Datenschutzerklärung auf unserer Website.</p>
</div>
);
};

export default PrivacyPolicy;
