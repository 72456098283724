import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchWithHeaders } from "./api";

const useEventExists = (eventId) => {
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvent = async () => {
            try {
                const response = await fetchWithHeaders(`/api/event_table/${eventId}/exists`);
                if (response.status !== 200) {
                    navigate('/404');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchEvent();

    }, [eventId, navigate]);
};

export default useEventExists;
