import React from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const BalloonSearch = ({ id, setId, search, balloon }) => {
    return (
        <Paper elevation={3} style={{ width: '300px', padding: '20px', marginLeft: '20px' }}>
            <h1 style={{ color: 'blue', textAlign: 'center' }}>Balloon Search</h1>
            <TextField
                id="outlined-basic"
                label="Balloon Search"
                variant="outlined"
                fullWidth
                value={id}
                onChange={(e) => setId(e.target.value)}
                sx={{ marginTop: 2 }}
            />
            <Button variant="contained" onClick={search} sx={{ marginTop: 2 }}>
                Search
            </Button>
            {balloon.id && (
                <Paper elevation={6} style={{ margin: '10px', padding: '15px', textAlign: 'left' }}>
                    ID: {balloon.id} <br />
                    Name: {balloon.name} <br />
                    Owner: {balloon.owner} <br />
                    Event: {balloon.event} <br />
                    Volume: {balloon.volume} <br />
                    Height: {balloon.height} <br />

                    {/* Extracting color information */}
                    Color: {`RGB(${balloon.color.red}, ${balloon.color.green}, ${balloon.color.blue})`} <br />

                    Longitude: {balloon.position ? balloon.position.x : 'N/A'} <br />
                    Latitude: {balloon.position ? balloon.position.y : 'N/A'} <br />

                    {/* Displaying locations if available */}
                    Locations:
                    {balloon.locations &&
                        balloon.locations.map((location, index) => (
                            <div key={index}>
                                Location {index + 1}: X={location.x}, Y={location.y}
                            </div>
                        ))}
                </Paper>
            )}
        </Paper>
    );
};

export default BalloonSearch;
