import CirclePatternStandard from '../../patterns/PatternCircle.svg'
import CirclePattern1 from './Circle1.svg'
import CirclePattern2 from './Circle2.svg'
import CirclePattern3 from './Circle3.svg'
import CirclePattern4 from './Circle4.svg'
import CirclePattern5 from './Circle5.svg'
import CirclePattern6 from './Circle6.svg'
import CirclePattern7 from './Circle7.svg'
import CirclePattern8 from './Circle8.svg'
import CirclePattern9 from './Circle9.svg'

export const circlePatterns = {
    standard: CirclePatternStandard,
    B1: CirclePattern1,
    B2: CirclePattern2,
    B3: CirclePattern3,
    B4: CirclePattern4,
    B5: CirclePattern5,
    B6: CirclePattern6,
    B7: CirclePattern7,
    B8: CirclePattern8,
    B9: CirclePattern9
};