export const bgColors = {
    "Green"     : "#7DA038",
    "LightBlue" : "#62B4EA",
    "DarkBlue"  : "#114884"
};

export const backButton = {
    position: "absolute",
    top: "20%",
    left: "20%",
    transform: "translate(-50%, -50%)",
    width: "90px",
    height: "40px",
    backgroundColor: "white",
    border: `3px solid ${bgColors.LightBlue}`,
    borderRadius: "10px",
    color: "darkBlue",
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: "bold",
    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)"
}

export const textRegistrationStyle = {
    position: "absolute",
    height: "40px",
    top: "30%",
    left: "15%"
};

export const lineStyle = {
    height: "3px",
    width: "40px",
    backgroundColor: bgColors.Green ,
    position: "absolute"
};

export const textFieldStyle = {
    position: "absolute",
    left: "25%",
    width: "68%",
    height: "80px",
    top: "53%",
    fontSize: "50px",
    border:  "2.5px solid #7DA038",
    borderRadius: "10px",
    negative: "false",
}