import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import Cookies from "js-cookie";

function PrivateRoute({ children }) {
    const { currentUser } = useAuth();
    const loggedIn = Cookies.get('auth_loggedIn');
    console.log(currentUser || loggedIn)
    return (currentUser || loggedIn) ? children : <Navigate to="/login" />;
}

export default PrivateRoute;
