import React, {useEffect, useState} from "react";
import Header from "../../components/Header";
import {useNavigate, useParams} from "react-router-dom";
import LeftRectFrame from "../../components/frames/LeftRectFrame";
import RightRectFrame from "../../components/frames/RightRectFrame";
import SubmitButton from "../../components/utils/SubmitButton";
import symbolRedo from '../../assets/redo-arrow-icon.svg'
import '../../assets/styles/NamingStyles.css'
import {fetchWithHeaders} from '../../components/utils/api'
import Cookies from "js-cookie";

const Naming = () =>{
    let navigate = useNavigate();
    const { eventId } = useParams();

    const navigateToColorPage = () => {
        navigate(`/${eventId}/color`)
    }

    const generateRandomName = async () => {
        const response = await fetchWithHeaders(`/api/balloon/nameGen?eventId=${eventId}`)
        const name = await response.text()
        return name
    };



    const [adjective, setAdjective] = useState('');
    const [noun, setNoun] = useState('');

    const handleGenerateNames = async () => {
        const newName = await generateRandomName();
        const parts = newName.split(';');
        setAdjective(parts[0]);
        setNoun(parts[1]);
        Cookies.set("UniqueName", parts[0]+parts[1], { expires: 7 });
    };

    useEffect(() => {
        handleGenerateNames();
    }, []);

    return (
        <div>
            <Header/>
            <div className="screen-size">

                <div className="frame-and-label-container-1">
                    <LeftRectFrame/>
                    <div className="text-positioning-1">
                        <label className="text-styling">{adjective}</label>
                    </div>
                </div>

                <div className="frame-and-label-container-2">
                    <RightRectFrame/>
                    <div className="text-positioning-2">
                        <label className="text-styling">{noun}</label>
                    </div>
                </div>

                <div className="buttons">
                    <button className="button-icon">
                        <img src={symbolRedo} alt="Redo Button Icon" onClick={handleGenerateNames} className="redo-icon"/>
                    </button>

                    <SubmitButton isFormValid={true} onClick={() => navigateToColorPage()}>
                        Namen setzen
                    </SubmitButton>
                </div>
            </div>
        </div>
    )
};

export default Naming;

