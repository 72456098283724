import useScreenSize from "../utils/ScreenDimensions"

const LeftRectFrame = () => {
    const screenSize = useScreenSize()
    return (
        <svg data-testid='left-rect-frame' width={screenSize.width * 0.9} viewBox="0 0 209 139" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="117.012" height="194.768" rx="10"
                  transform="matrix(0.0456434 0.998958 -0.997732 0.0673117 194.659 0)" fill="#62B4EA"
                  fillOpacity="0.5"/>
            <rect width="117.033" height="194.709" rx="10"
                  transform="matrix(0.0304311 0.999537 -0.998967 0.0454492 194.659 0)" fill="#62B4EA"
                  fillOpacity="0.75"/>
            <rect width="117.046" height="194.672" rx="10"
                  transform="matrix(0.0152162 0.999884 -0.999731 0.0232012 194.659 0)" fill="#62B4EA"/>
            <rect x="193.659" y="1" width="115.051" height="192.659" rx="9" transform="rotate(90 193.659 1)"
                  fill="white" stroke="#62B4EA" strokeWidth="2"/>
        </svg>
    )
}

export default LeftRectFrame