import React from 'react';

const CheckboxInput = ({ id, checked, onChange, label, link }) => {
    return (
        <div style={{ marginTop: '4vh', marginLeft: '25vw', display: 'flex', alignItems: 'flex-start' }}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onChange}
                className="checkbox-input"
                style={{ marginRight: '5vw', marginTop: '1vh', width: '5vw', height: '3vh'}}
            />
            <div style={{ textAlign: 'left' }}>
                <label htmlFor={id}>
                    <span>{label}</span><br />
                    <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
                        Datenschutzerklärung
                    </a>
                </label>
            </div>
        </div>
    );
};

export default CheckboxInput;