import React from 'react';
import transparentBalloon from "./assets/transparent.svg";
import logoutImage from "./assets/logout.jpg";
import {useAuth} from "../organizerLogin/AuthContext";
import {useNavigate} from "react-router-dom";
import { useState } from 'react';
import AargauSolothurnLogo from "../../../assets/imgs/aargausolothurn_farb_transparent.jpg"
import AargauTourismusLogo from "../../../assets/imgs/logoBrand.png"


const Header = () => {
  const {logout} = useAuth();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState({AargauTourismusLogo});

  const changeLogo = (newLogo) => {
    setSelectedLogo(newLogo);
  }
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleList = () => {
    navigate('/dashboard');
  }

  const handleArchive = () => {
    navigate('/archive')
  }

  const handleCreateEvent = () =>  {
    navigate("/editor")
  }


  return (
      <header className="text-gray-600 body-font">
        <div
            className="mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            <img src={transparentBalloon} className="w-20 h-20" alt="Balloon"/>
              <span className="ml-3 text-2xl bold" onClick={handleList}>PocketBalloon</span>

          </a>
          <nav
              className="md:ml-auto flex flex-wrap items-center text-base justify-center">
            
            <select onChange={(e) => changeLogo(e.target.value)} style={{margin: '20px'}}>
              <option value={AargauTourismusLogo}>Logo Aargau Tourismus</option>
              <option value={AargauSolothurnLogo}>Logo Aargau Solothurn</option>
            </select>

            <a className="mr-5 hover:text-gray-900" onClick={handleCreateEvent}
            style={{cursor:'pointer'}}
            >Create Event</a>

            <a className="mr-5 hover:text-gray-900"
               onClick={handleArchive} style={{cursor:'pointer'}}>Archive</a>
          </nav>
          <button
              className="inline-flex items-center bg-white border-0 py-1 px-3 focus:outline-none rounded text-base mt-4 md:mt-0">
            <img src={logoutImage} className="w-10 h-10" onClick={handleLogout}
                 alt="Balloon"/>
          </button>
        </div>
      </header>
  );
}

export default Header