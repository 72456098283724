import useScreenSize from "../utils/ScreenDimensions"

const RectFrame = () => {
    const imageContainerStyle = {
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "100%"
    };

    const imageStyleRectFrame = {
        display: "flex",
        marginRight: "5%"
    };

    const screenSize = useScreenSize()

    return (
        <svg width={screenSize.width} height={screenSize.height * 0.9} viewBox="-10 0 380 668" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="340" height="650" rx="10" transform="matrix(0.99863 -0.052336 0.0587167 0.998275 0 18)"
                  fill="#62B4EA" fillOpacity="0.5"/>
            <rect width="340" height="650" rx="10"
                  transform="matrix(0.999391 -0.0348995 0.0396342 0.999214 0 18)" fill="#62B4EA"
                  fillOpacity="0.75"/>
            <rect width="340" height="650" rx="10"
                  transform="matrix(0.999848 -0.0174524 0.020229 0.999795 0 18)" fill="#62B4EA"/>
            <rect x="1" y="19" width="338" height="648" rx="9" fill="white" stroke="#62B4EA" strokeWidth="2"/>
        </svg>
    );
}

export default RectFrame