import Header from "../../components/Header";
import './BalloonMapStyles.css';
import { useState, useEffect } from "react";
import { initializeMap } from "./BalloonMap";
import 'leaflet/dist/leaflet.css';
import 'leaflet-tilelayer-swiss/dist/Leaflet.TileLayer.Swiss.umd.js';
import Cookies from 'js-cookie';
import { fetchWithHeaders } from "../../components/utils/api";
import { createBalloonWithIcon } from "./BalloonMap";
import L from 'leaflet';
import RectFrame from "../../components/frames/RectFrame";
import {balloonColors} from '../../assets/colors/Colors';
import {colorPattern} from '../../assets/full_balloon_pattern/patternColor';

const EndingPage = () => {
    const map = 'mapCH_endMap';

    const [zoomLevel, setZoomLevel] = useState(8);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);
    const [routeLength, setRouteLength] = useState(0);
    const [color, setColor] = useState(null);
    const [pattern, setPattern] = useState(null);
    const [balloonName, setBalloonName] = useState(null);
    const [balloon, setBalloon] = useState(null);

    const fetchBalloonData = async (balloonName) => {
        console.log(balloonName);
        return fetchWithHeaders(`/api/balloon/?name=${balloonName}`, { method: "GET" })
            .then(response => {
                if (!response.ok) {
                    console.log("There was a network error");
                    console.log(response.status);
                    console.log(response.statusText);
                }
                return response.json();
            })
            .then(data => { return data[0]; });
    }

    const displayBalloonRoute = async () => {
        initializeMap(zoomLevel, 'endMap');

        if (window[map]) {
            setBalloonName(Cookies.get("UniqueName"));
            let balloon = await fetchBalloonData(Cookies.get("UniqueName"));
            balloon = createBalloonWithIcon(balloon);

            const length = balloon.locations.length - 1;
            const points = balloon.locations.map(loc => [loc.y, loc.x]);

            // Fit map to route bounds
            const bounds = L.latLngBounds(points);
            window[map].fitBounds(bounds, { padding: [50, 50] });

            const polyLine = L.polyline(points, { color: "red" });
            polyLine.addTo(window[map]);

            const marker = L.marker([balloon.locations[length].y, balloon.locations[length].x], { icon: balloon.icon });
            marker.addTo(window[map]);

            // calculating some statistics
            let totalMinutes = balloon.locations.length * 5;
            let d = Math.floor(totalMinutes / (60 * 24));
            let h = Math.floor((totalMinutes % (60 * 24)) / 60);
            setHours(h);
            setDays(d);
            const color = balloonColors[balloon.BalloonColor];
            const pattern = colorPattern[balloon.Pattern][balloon.PatternColor];
            balloon.icon = `<div style="position: relative; width: 70px; height: 70px; z-index: 999;">
                    <img src="${color}" style="position: absolute; top: 0; left: -28px; width: 100%; height: 100%;" />
                    <img src="${pattern}" style="position: absolute; top: 7px; left: -28px; width: 100%; height: 50%;" />
                    </div>`;
            setBalloon(balloon);
            setRouteLength(Math.floor(balloon.routeLength / 1000));
            setColor(Cookies.get("selectedColor"));
            setPattern(Cookies.get("selectedColorPatternName"));
        }
    }

    useEffect(() => {
        displayBalloonRoute();
        const intervalId = setInterval(() => {
            displayBalloonRoute();
        }, 60000); // Fetch data every 60 seconds

        return () => {
            clearInterval(intervalId);
            if (window[map]) {
                window[map].remove();
                delete window[map];
            }
        };
    }, [window[map]]);

    return (
        <div className="container">
            <Header/>
                <RectFrame/>
            <div className="ending-page-content">
                <label>Statistik von {balloonName}</label>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '30px'}}>
                    <div dangerouslySetInnerHTML={{__html: balloon?.icon}}/>
                </div>
                <div style={{margin: 10}}><label>{days} Tage {hours} Stunden</label></div>
                <div><label>Gesamt Strecke: {routeLength} km</label></div>
                <div className="endpage-map" id="endMap" data-testid="endMap"></div>
            </div>
        </div>
    );
}

export default EndingPage;
