import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import "../../assets/styles/RegistrationStyles.css";
import {useNavigate, useParams} from "react-router-dom";
import { balloon } from '../../components/utils/utils';
import { buttonColors, balloonColors } from '../../assets/colors/Colors';
import { bgColors } from '../../components/utils/utils';
import Cookies from "js-cookie";


const ColorCustomizer = () => {
    let navigate = useNavigate();
    const { eventId } = useParams();

    const navigateToPatternPage = () => {
        navigate(`/${eventId}/pattern`)
    }


    useEffect(() => {
        const savedColor = Cookies.get('selectedColor');
        if (savedColor && balloonColors[savedColor]) {
            setSelectedBalloon(balloonColors[savedColor]);
            setSelectedColorName(savedColor);
        }
    }, []);

    const [selectedBalloon, setSelectedBalloon] = useState(balloonColors.standard);
    const [selectedColorName, setSelectedColorName] = useState('');

    const handleColorChange = (color) => {
        console.log(balloon.color)
        console.log(balloon.name)
        setSelectedBalloon(balloonColors[color]);
        setSelectedColorName(color);
        balloon.color = color;
        Cookies.set('selectedColor', color, { expires: 7 });
    };

    return (
        <div style={{position: 'relative', height: '100%'}}>

            <Header/>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                <img
                    src={selectedBalloon}
                    alt="Balloon"
                    style={{
                        width: '60%',
                        marginBottom: "2vh"
                    }}
                />
            </div>


            <label htmlFor="checkboxId" style={{
                display: "block",
                alignItems: "center",
                minWidth: "100%",
                textAlign: "center",
                fontSize: "20px",
                marginBottom: "3vh"
            }}>Bestimmen Sie die Farbe des Balloons:</label>

            <div style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '10px',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '260px',
                margin: '10px auto',
            }}>
                {Object.entries(buttonColors).map(([colorName, colorValue]) => (
                    <button
                        key={colorName}
                        onClick={() => handleColorChange(colorName)}
                        style={{
                            backgroundColor: selectedColorName === colorName ? '#D3D3D3' : 'transparent',
                            width: "80px",
                            height: '80px',
                            border: '3px solid #62B4EA',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            position: 'relative',
                            boxShadow: '4px 4px 5px rgba(0, 0, 0, 0.3)'
                        }}
                    >
                        <div style={{
                            backgroundColor: colorValue,
                            width: '55px',
                            height: '55px',
                            border: '3px solid black',
                            borderRadius: '50%',
                        }}></div>
                    </button>
                ))}
            </div>
            <button
                type="button"
                onClick={navigateToPatternPage}
                disabled={!selectedColorName}
                style={{
                    display: 'block',
                    margin: '10px auto',
                    marginTop: "5vh",
                    width: "200px",
                    height: "65px",
                    backgroundColor: "white",
                    border: `3px solid ${bgColors.LightBlue}`,
                    borderRadius: "10px",
                    color: "darkBlue",
                    fontSize: "25px",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)",
                }}>Weiter
            </button>
        </div>
    );
};

export default ColorCustomizer;
