import React, {useState} from 'react';
import {fetchWithHeaders} from "../../../components/utils/api";
import {useNavigate} from "react-router-dom";


const Editor = () => {
  const [eventName, setEventName] = useState("");
  const [eventDate, setEventDate] = useState(null);
  const [eventCanton, setEventCanton] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [eventTime, setEventTime] = useState(0);

  const navigate = useNavigate();
    
  const validData = () => {
    return eventName !== "" && eventCanton !== "" && eventDate !== null && longitude !== null && latitude !== null && eventTime !== 0;
  }

  const handleSave = () => {

    if(!validData()) {
      alert("All fields must be filled")
      return;
    }

    const eventData = {
      name: eventName,
      date: new Date(eventDate),
      canton: eventCanton,
      longitude: longitude,
      latitude: latitude,
      started: false,
      eventTime: eventTime,
    };

    fetchWithHeaders(`/api/event_table/save`, {
      method: "POST",
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(eventData)
    })

    navigate("/dashboard")
  }


  return (
      <header className="text-gray-600 body-font">
        <div
            className="container mx-auto py-16 flex flex-col items-center space-y-8">

          <div className="w-full flex space-x-64">
            <div className="w-0.5">
              <label htmlFor="eventName"
                     className="block text-lg font-medium text-gray-700">Name</label>
              <input type="text" id="eventName" name="eventName" onChange={(e) => setEventName(e.target.value)}
                     className="mt-2 block w-40 h-10 py-2 px-3 border border-gray-300 bg-gray-200 text-gray-700 rounded-md shadow-sm focus:outline-none sm:text-lg"/>
            </div>

            <div className="w-0.5">
              <label htmlFor="canton"
                     className="block text-lg font-medium text-gray-700">Canton</label>
              <input type="text" id="canton" name="canton" onChange={(e) => setEventCanton(e.target.value)}
                     className="mt-2 block w-40 h-10 py-2 px-3 border border-gray-300 bg-gray-200 text-gray-700 rounded-md shadow-sm focus:outline-none sm:text-lg"/>
            </div>

            <div className="w-0.5">
              <label htmlFor="status"
                     className="block text-lg font-medium text-gray-700">Duration(h)</label>
              <input type="number" id="status" name="duration"
                     onChange={(e) => setEventTime(e.target.value)}
                     className="mt-2 block w-40 h-10 py-2 px-3 border border-gray-300 bg-gray-200 text-gray-700 rounded-md shadow-sm focus:outline-none sm:text-lg"
                     />
            </div>

          </div>

          <div className="w-full flex space-x-64">
            <div className="w-full">
              <label htmlFor="Date"
                     className="block text-lg font-medium text-gray-700">Date</label>
              <input type="date" id="date" name="date" onChange={(e) => setEventDate(e.target.value)}
                     className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-10 shadow-sm sm:text-lg border-gray-300 rounded-md"/>
            </div>
          </div>

          <div className="w-full flex space-x-64">
            <div className="w-full">
              <label htmlFor="longitude"
                     className="block text-lg font-medium text-gray-700">Longitude</label>
              <input type="text" id="longitude" name="longitude" onChange={(e) => setLongitude(e.target.value)}
                     className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-10 shadow-sm sm:text-lg border-gray-300 rounded-md"/>
            </div>

            <div className="w-full">
              <label htmlFor="latitude"
                     className="block text-lg font-medium text-gray-700">Latitude</label>
              <input type="text" id="latitude" name="latitude" onChange={(e) => setLatitude(e.target.value)}
                     className="mt-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full h-10 shadow-sm sm:text-lg border-gray-300 rounded-md"/>
            </div>
          </div>

          <div className="max-w-lg mt-32">
            <label
                className="flex justify-center w-full h-48 px-8 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
        <span className="flex items-center space-x-4">
            <svg xmlns="http://www.w3.org/2000/svg"
                 className="w-12 h-12 text-gray-600" fill="none"
                 viewBox="0 0 24 24"
                 stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
            </svg>
            <span className="font-medium text-gray-600">
                Drag and Drop Balloon Icons, or
              <span
                  className="text-blue-600 underline"> browse your files</span>
            </span>
        </span>
              <input type="file" name="file_upload" className="hidden"/>
            </label>
          </div>

        </div>

        <div>
          <div className="absolute top-4 right-4">
            <button
                style={{
                  backgroundColor: 'blue',
                  width: '100px',
                  height: '50px'
                }}
                className="text-white font-bold py-2 px-4 rounded"
                onClick={handleSave}>
              Save
            </button>
          </div>
        </div>

      </header>
  );
}

export default Editor;