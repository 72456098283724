import Header from '../../components/Header'
import React from 'react';
import RectFrame from '../../components/frames/RectFrame'
import textRegistration from '../../assets/registration/textRegistration.svg'
import {useNavigate, useParams} from "react-router-dom";
import { handleUserRegistration, fetchUserData } from '../../components/utils/Credential';
import { useState } from 'react';
import { user, verification } from './Registration';
import { bgColors } from '../../assets/colors/Colors';
import { fetchWithHeaders } from '../../components/utils/api';
import Cookies from 'js-cookie';

import {
    textRegistrationStyle,
    lineStyle,
    textFieldStyle, backButton
} from '../../assets/styles/VerificationStyles';


const Verification = () => {
    const navigate = useNavigate();
    const [code, setCode] = useState('');
    const userInput = code;
    const { eventId } = useParams();
    const email = Cookies.get('email');

    const navigateToNaming = async (e) => {
        e.preventDefault();
        console.log("The user input was: " + userInput);
        try {
            let baseURL = process.env.REACT_APP_API_URL;
            const response = await fetchWithHeaders(`/api/User_table/verify?email=${user.email}&code=${userInput}`, { method: 'POST' });
            if (response.status === 200) {
                navigate(`/${eventId}/naming`);
            } else {
                alert('The given code is wrong');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const navigateBack = () => {
        navigate('/registration');
    };

    return (
        <div style={{position: 'relative', height: '100%'}}>
            <Header/>
            <RectFrame/>

            <button type="button" onClick={navigateBack} style={backButton}> Zurück
            </button>

            <img src={textRegistration} style={textRegistrationStyle} alt="textRegistration"/>

            <div style={{
                position: "absolute",
                top: "45%",
                left: "12%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <label htmlFor="checkboxId" style={{}}>Sie haben via Mail einen Code erhalten:</label>
                <h1>{email}</h1>
            </div>

            <div style={{...lineStyle, top: "60%", left: "12%"}}></div>

            <form onSubmit={navigateToNaming}>
                <input
                    type="text"
                    maxLength="4"
                    pattern="[0-9]*"
                    style={{...textFieldStyle, left: "12%", textAlign: "center"}}
                    onChange={(e) => {
                        if (!/[0-9]/.test(e.target.value)) {
                            e.target.value = '';
                        }
                        setCode(e.target.value)
                    }}
                />

                <button type="submit" style={{
                    position: "absolute",
                    top: "90%",
                    left: "45%",
                    transform: "translate(-50%, -50%)",
                    width: "200px",
                    height: "65px",
                    backgroundColor: "white",
                    border: `3px solid ${bgColors.LightBlue}`,
                    borderRadius: "10px",
                    color: "darkBlue",
                    fontSize: "25px",
                    fontFamily: "Inter",
                    fontWeight: "bold",
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.25)"
                }}> Check!
                </button>
            </form>
        </div>
    )
};

export default Verification;