import { balloonColors } from "../../assets/colors/Colors";

export const balloon = {
    name: "",
    owner: "",
    color: balloonColors.standard,
    pattern: "",
    patternName: "",
    colorPattern:"",
    colorPatternName:"",
    air: ""
}

export const bgColors = {
    "Green"     : "#7DA038",
    "LightBlue" : "#62B4EA",
    "DarkBlue"  : "#114884"
};

export const user = {
    name: "",
    email: "",
    street: "",
    postalCode: "",
    location: "",
    events: [], // Assuming events are not captured in the form, initialize as an empty list
};

export const createCSV= (owners) => {
    // Define the headers for the CSV file
    const headers = ["Name", "Email", "Address", "rank"];
    
    // Map over the owners to create CSV rows
    const rows = owners.map(owner => [
      `"${owner.name}"`,
      `"${owner.email}"`,
      `"${owner.address}"`,
      `"${owner.rank}"`
    ].join(','));
  
    // Combine headers and rows
    const csvContent = [
      headers.join(','), // Add the headers as the first row
      ...rows            // Add all other rows
    ].join('\n');

    console.log(csvContent);
    startCSVDownload(csvContent);
    return csvContent;
}

function startCSVDownload(csv) {
    const blob = new Blob([csv], {type: 'application/csv'});
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.download = "UserData.csv";
    a.href = url;
    a.style.display = "none";

    document.body.appendChild(a);

    a.click();
    a.remove();

    URL.revokeObjectURL(url);
}
