import React, { useState } from "react";
import {fetchWithHeaders, baseURL} from "./api";
import IDContext from './IDContext';
import {useContext} from 'react';
const local = true;


export function validateCredentials(name, street, postalCode, location, email) {
    // Regex patterns with support for umlauts and other accented characters
    const nameFormat = /^[a-zA-Z\u00C0-\u017F- ]+$/;
    const streetFormat = /^[a-zA-Z0-9\u00C0-\u017F- ]+$/;
    const postalCodeFormat = /^[0-9a-zA-Z- ]+$/;
    const locationFormat = /^[a-zA-Z\u00C0-\u017F- ]+$/;
    const emailFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Check if any field is empty
    if (!name.trim() || !street.trim() || !postalCode.trim() || !location.trim() || !email.trim()) {
        throw new Error('Fields cannot be empty');
    }

    // Check name format
    if (!nameFormat.test(name)) {
        throw new Error('This name is not valid: ' + name);
    }

    // Check street format
    if (!streetFormat.test(street)) {
        throw new Error('This street is not valid: ' + street);
    }

    // Check postal code format
    if (!postalCodeFormat.test(postalCode)) {
        throw new Error('This postal code is not valid: ' + postalCode);
    }

    // Check location format
    if (!locationFormat.test(location)) {
        throw new Error('This location is not valid: ' + location);
    }

    // Check email format
    if (!emailFormat.test(email)) {
        throw new Error('This email is invalid');
    }
}


export const isEmailUnique = async (emailToCheck) => {
    const response = await fetchWithHeaders(`/api/User_table/isEmailUnique?email=${emailToCheck}`);
    const result = await response.json();
    return result.isUnique;
};



export const handleUserRegistration = (user) => {
    // Process the user registration logic here
    console.log('Registering user:', user, user.email);

    fetchWithHeaders(`/api/User_table/add`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
    }).then(() => {
        console.log('User registered successfully');
    });
};


export const doubleOptIn = async (email) => {
    
    //get the verification code from the backend and return it
    console.log('The double opt in mail is send');
    const response = await fetchWithHeaders(`/api/User_table/verification?email=${email}`);
    const verification = await response.text()
    console.log(verification)
    return verification;
}



export const checkCredentials = async (name, email, street, postalCode, location) => {
    // Validate form fields before proceeding
    try {
        validateCredentials(name, street, postalCode, location, email);
    } catch (error) {
        throw new Error(error.message);
    }
    // Check if the email is unique
    const emailIsUnique = await isEmailUnique(email);
    if (!emailIsUnique) {
        throw new Error('Email is already registered. Please enter a different email address.');
    }
};

export function createUser (name, email, street, postalCode, location) {
    const user = Object.freeze({
        name: name,
        email: email,
        street: street,
        postalCode: postalCode,
        location: location,
        events: [], // Assuming events are not captured in the form, initialize as an empty list
    });
    return user;
}


