import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import Header from "./Header";


const Archive = () => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('/editor')
  }

  const [events, setEvents] = useState([
    {
      eventName: "Done Event",
      eventDate: "3.3.2024",
      eventLocation: "Basel",
      eventCanton: "Basel-Stadt"
    },
  ]);

  return (
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Header/>
        <table
            className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead
              className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b-2 border-gray-200">
          <tr>
            <th scope="col" className="px-6 py-3">
              Eventname
            </th>
            <th scope="col" className="px-6 py-3">
              Datum
            </th>
            <th scope="col" className="px-6 py-3">
              Ort
            </th>
            <th scope="col" className="px-6 py-3">
              Kanton
            </th>
            <th scope="col" className="px-6 py-3">
              Öffnen
            </th>
          </tr>
          </thead>
          <tbody>
          {events.map((event, index) => (
              <tr key={index}
                  className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                <th scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {event.eventName}
                </th>
                <td className="px-6 py-4">
                  {event.eventDate}
                </td>
                <td className="px-6 py-4">
                  {event.eventLocation}
                </td>
                <td className="px-6 py-4">
                  {event.eventCanton}
                </td>
                <td className="px-6 py-4">
                  <button onClick={handleEdit}
                          className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit
                  </button>
                </td>
              </tr>
          ))}
          </tbody>
        </table>

      </div>
  );

}

export default Archive;