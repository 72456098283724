// BalloonForm.js
import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';

const BalloonForm = ({ generateName, handleClick, handleChange, name, color, handleColorChange, checked, id, setId, search, userEmail, addToVolume }) => {
    return (
        <Paper elevation={3} style={{ width: '300px', padding: '20px' }}>
            <h1 style={{ color: 'blue', textAlign: 'center' }}>Balloon Form</h1>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    id="outlined-basic"
                    label="Balloon Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    InputProps={{ readOnly: true }}
                />
                <Button variant="contained" onClick={generateName} sx={{ marginTop: 2 }}>
                    Generate Name
                </Button>

                <div style={{ display: 'flex', gap: '10px' }}>
                    <TextField
                        id="outlined-basic-red"
                        label="Red"
                        variant="outlined"
                        fullWidth
                        type="number"
                        margin="dense"
                        inputProps={{ min: 0, max: 255 }}
                        value={color.red}
                        onChange={(e) => handleColorChange('red', e.target.value)}
                    />

                    <TextField
                        id="outlined-basic-green"
                        label="Green"
                        variant="outlined"
                        fullWidth
                        type="number"
                        margin="dense"
                        inputProps={{ min: 0, max: 255 }}
                        value={color.green}
                        onChange={(e) => handleColorChange('green', e.target.value)}
                    />

                    <TextField
                        id="outlined-basic-blue"
                        label="Blue"
                        variant="outlined"
                        fullWidth
                        type="number"
                        margin="dense"
                        inputProps={{ min: 0, max: 255 }}
                        value={color.blue}
                        onChange={(e) => handleColorChange('blue', e.target.value)}
                    />
                </div>

                {/* Display the current color values */}
                <div style={{ marginTop: '16px', width: '100%' }}>
                    Current Color: RGB({color.red}, {color.green}, {color.blue})
                </div>



                <Button variant="contained" onClick={handleClick}  sx={{ marginTop: 2 }}>
                    Start!
                </Button>

                <Button variant="contained" onClick={addToVolume}  sx={{ marginTop: 2 }}>
                    Add to volume
                </Button>

            </Box>
        </Paper>
    );
};

export default BalloonForm;
