import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import BalloonForm from './BalloonForm';
import BalloonSearch from './BalloonSearch';
import BalloonList from './BalloonList';
import UserForm from './UserForm';
import UserList from './UserList';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import NavBar from '../NavBar';
import { fetchWithHeaders ,  baseURL } from "../utils/api";

const containerStyle = {
    display: 'flex',
    flexDirection: 'row', // This ensures items are displayed horizontally
    justifyContent: 'center',
    marginTop: '20px', // Add some spacing between the top of the page and the content
};

const formStyle = {
    width: '300px',
    padding: '20px',

};

const listStyle = {
    width: '300px',
    padding: '20px',
    marginLeft: '20px',
};


const Balloon = () => {
    const [name, setName] = useState('');
    const[Email, setEmail] = useState('');
    const [id, setId] = useState(0);
    const [balloons, setBalloons] = useState([]);
    const[users, setUsers] = useState([]);
    const [longitude, setLongitude] = useState(0.0);
    const [latitude, setLatitude] = useState(0.0);
    const [balloon, setBalloon] = useState([]);
    const [checked,setChecked] = useState(false);
    const [color, setColor] = useState({ red: 255, green: 0, blue: 0 });

    const handleChange = () => {
        setChecked(!checked);
            getGeoLocation();
    }
    const getGeoLocation = () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                reject('Geolocation not supported');
            }
        });

        function success(position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
        }

        function error() {
            console.log('Unable to retrieve your location');
        }
    };


    const handleClick = async (e) => {
        e.preventDefault();
        getGeoLocation();

        const balloon = {
            name,
            color,
            position: { x: latitude, y: longitude }, // Update position based on latitude and longitude
        };        console.log(balloon);

        await fetchWithHeaders(`/api/balloon/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(balloon),
        }).then(() => {
            console.log('New Balloon Added');
            fetchData();
        });
    };

    const search = (e) => {
        console.log('entering search');
        e.preventDefault();
        const value = id;
        fetchWithHeaders(`/api/balloon/get?id=${value}`)
            .then((response) => response.json())
            .then((data) => {
                setBalloon(data);
            });
    };

    const fetchData = () => {
        fetchWithHeaders(`/api/balloon/getAll`)
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => b.id - a.id);
                setBalloons(data);
            });
    };

    const fetchUserData = () => {
        fetchWithHeaders(`/api/User_table/getAll`)
            .then((response) => response.json())
            .then((data) => {
                data.sort((a, b) => b.id - a.id);
                setUsers(data);
            });
    };

    const addToVolume = () => {
        fetchWithHeaders(`/api/balloon/update`, {
            method: 'Put'
        })
        fetchData()
    }

    const handleColorChange = (channel, value) => {
        setColor((prevColor) => ({
            ...prevColor,
            [channel]: parseInt(value, 10) || 0,
        }));
    };

    const handleUserRegistration = (user,mail) => {
        // Process the user registration logic here
        console.log('Registering user:', user, mail);
        setEmail(mail);
        console.log('Email added', Email);

        fetchWithHeaders(`/api/User_table/add`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(user),
        }).then(() => {
            console.log('User registered successfully');
            // You can perform additional actions after successful registration
        });
    };

    const generateName = async () => {
        const response = await fetchWithHeaders("/api/ballon/nameGen");
        const name = await response.text();
        return name;
    }

    useEffect(() => {
        fetchData();
        fetchUserData();
        document.title = "PB Dev Test";
    }, []);

    return  (


        <>
            <NavBar/>

            <Container style={containerStyle} >


                <Routes>

                    <Route
                        path="/pages/registrationPage/Registration"
                        element={
                            <Box style={{ display: 'flex', gap: '20px' }}>
                                <UserForm
                                    onRegister={handleUserRegistration}
                                    handleChange={handleChange}
                                    checked={checked}
                                />
                                <UserList />


                            </Box>
                        }
                    />




                    <Route
                        path="/user-form"
                        element={
                            <Box style={{ display: 'flex', gap: '20px' }}>
                                <UserForm
                                    onRegister={handleUserRegistration}
                                    handleChange={handleChange}
                                    checked={checked}
                                />
                                <UserList />


                            </Box>
                        }
                    />

                    <Route
                        path="/balloon-form"
                        element={
                            <Box style={{ display: 'flex', gap: '20px' }}>
                                <BalloonForm
                                    generateName={generateName}
                                    handleClick={handleClick}
                                    handleChange={handleChange}
                                    addToVolume={addToVolume}
                                    name={name}
                                    color={color}
                                    handleColorChange={handleColorChange}
                                    checked={checked}
                                    id={id}
                                    setId={setId}
                                    search={search}
                                    userEmail={Email}
                                />
                                <BalloonList balloons={balloons} />
                                <BalloonSearch id={id} setId={setId} search={search} balloon={balloon} />
                            </Box>
                        }
                    />
                </Routes>
            </Container>
        </>
    );

};

export default Balloon;
