import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import '../../assets/styles/RegistrationStyles.css'
import logoMail from '../../assets/imgs/EMail.png';
import Header from "../../components/Header";
import RectFrame from "../../components/frames/RectFrame";
import TextInput from "../../components/utils/TextInput";
import SubmitButton from "../../components/utils/SubmitButton";
import useEventExists from "../../components/utils/useEventExists";
import {fetchWithHeaders, getGeoLocation} from "../../components/utils/api";
import Cookies from "js-cookie";

const LandingPage = () => {
    const {eventId} = useParams();

    useEventExists(eventId);
    let navigate = useNavigate();

    const [email, setEmail] = useState('');



    const navigateToMap = () => {
        fetchWithHeaders(`/api/User_table/login?email=${email}&eventId=${eventId}`)
            .then(response => {
                if (response.status === 404) {
                    alert("Benutzer existiert nicht, bitte registrieren");
                    navigate(`/${eventId}/registration`);
                    throw new Error('Network response was not ok');
                } else if (response.status === 428) {
                  console.log('User existiert, aber kein Ballon für das aktuelle Event');
                  Cookies.set("email", email, {expires: 7});
                  navigate(`/${eventId}/naming`);
                  throw new Error('Network response was not ok');
                } else  {
                    console.log(response.status);
                }
                return response.text();
            })
            .then(balloonName => {
                if (balloonName) {
                    console.log('balloonName:', balloonName);
                    Cookies.set("UniqueName", balloonName, {expires: 7});
                    navigate(`/${eventId}/map`);
                } else {
                    alert('User does not exist');
                }
            })
            .catch(error => {
                console.error('There has been a problem with your fetch operation:', error);
            });
    };
    const navigateToRegistration = () => {
        navigate(`/${eventId}/registration`);
    };
    const navigateToOverview = () => {
        navigate(`/${eventId}/overview`);
    }

    return (
        <div>
            <Header/>
            <div className="rect-frame-content">
                <RectFrame/>
                <div className="registration-content">
                    <div style={{marginTop: 50}}></div>
                    <SubmitButton isFormValid={true} onClick={navigateToRegistration}>Registrieren</SubmitButton>

                    <hr style={{marginTop: '22vh'}}/>
                    <h2 style={{textAlign: 'center', marginTop: '2vh'}}>Bereits registriert?</h2>

                    <div className="flex-container" style={{marginTop: '2vh'}}>
                        <img src={logoMail} alt="E-Mail Address icon" className="icon"/>
                        <TextInput inputType="info" placeholder="E-Mail" value={email}
                                   onChange={(e) => setEmail(e.target.value)}/>
                    </div>

                    <SubmitButton isFormValid={email} onClick={navigateToMap}>Login</SubmitButton>
                </div>
            </div>
        </div>
    );

}
export default LandingPage;