import React, { createContext, useContext, useState, useEffect} from 'react';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}



export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const navigate = useNavigate();

        // Sync state with cookies on component mount
        useEffect(() => {
            const loggedIn = Cookies.get('auth_loggedIn');
            const user = Cookies.get('auth_user');
            if (loggedIn && user) {
                setCurrentUser({ username: user });
            }
        }, []); // Runs once on mount

    const login = (username, password) => {
        if (username !== "admin" || password !== "FHNW2024") {
            alert("Falscher Benutzername oder Password")
            return;
        }

        setCurrentUser({ username });
        Cookies.set('auth_user', username, { expires: 1 });
        Cookies.set('auth_loggedIn', true, { expires: 1 });
        navigate("/dashboard")
    };

    const logout = () => {
        setCurrentUser(null);
        Cookies.remove('auth_user');
        Cookies.remove('auth_loggedIn');
        navigate("/login");
    };

    const value = {
        currentUser,
        login,
        logout
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};