import Balloon1 from './1.svg';
import Balloon2 from './2.svg';
import Balloon3 from './3.svg';
import Balloon4 from './4.svg';
import Balloon5 from './5.svg';
import Balloon6 from './6.svg';
import Balloon7 from './7.svg';
import Balloon8 from './8.svg';
import Balloon9 from './9.svg';
import BalloonTransparent from './transparent.svg';

export const balloonColors = {
    standard: BalloonTransparent,
    B1: Balloon1,
    B2: Balloon2,
    B3: Balloon3,
    B4: Balloon4,
    B5: Balloon5,
    B6: Balloon6,
    B7: Balloon7,
    B8: Balloon8,
    B9: Balloon9,
};

export const buttonColors = {
    B1: '#C31622',
    B2: '#750363',
    B3: '#A20087',
    B4: '#62B4EA',
    B5: '#114884',
    B6: '#1493D7',
    B7: '#CEDB2B',
    B8: '#7DA038',
    B9: '#7FB73F',
};

export const bgColors = {
    "Green"     : "#7DA038",
    "LightBlue" : "#62B4EA",
    "DarkBlue"  : "#114884"
};