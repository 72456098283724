import RectanglePatternStandard from '../../patterns/PatternRectangles.svg'
import RectanglePattern1 from './Rectangle1.svg'
import RectanglePattern2 from './Rectangle2.svg'
import RectanglePattern3 from './Rectangle3.svg'
import RectanglePattern4 from './Rectangle4.svg'
import RectanglePattern5 from './Rectangle5.svg'
import RectanglePattern6 from './Rectangle6.svg'
import RectanglePattern7 from './Rectangle7.svg'
import RectanglePattern8 from './Rectangle8.svg'
import RectanglePattern9 from './Rectangle9.svg'

export const rectanglePatterns = {
    standard: RectanglePatternStandard,
    B1: RectanglePattern1,
    B2: RectanglePattern2,
    B3: RectanglePattern3,
    B4: RectanglePattern4,
    B5: RectanglePattern5,
    B6: RectanglePattern6,
    B7: RectanglePattern7,
    B8: RectanglePattern8,
    B9: RectanglePattern9
};
