import useScreenSize from "../utils/ScreenDimensions"

const RightRectFrame = () => {
    const screenSize = useScreenSize()
    return (
        <svg data-testid='right-rect-frame' width={screenSize.width * 0.9} viewBox="0 0 209 139" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect width="116.985" height="194.778" rx="10"
                  transform="matrix(-0.045555 -0.998962 0.997723 -0.0674421 5.32935 130)" fill="#62B4EA"
                  fillOpacity="0.5"/>
            <rect width="117.007" height="194.72" rx="10"
                  transform="matrix(-0.0303721 -0.999539 0.998963 -0.0455375 5.32935 130)" fill="#62B4EA"
                  fillOpacity="0.75"/>
            <rect width="117.02" height="194.683" rx="10"
                  transform="matrix(-0.0151867 -0.999885 0.99973 -0.0232464 5.32935 130)" fill="#62B4EA"/>
            <rect x="6.32935" y="129" width="115.024" height="192.671" rx="9" transform="rotate(-90 6.32935 129)"
                  fill="white" stroke="#62B4EA" strokeWidth="2"/>
        </svg>
    )
}

export default RightRectFrame